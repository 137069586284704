import { useMutation } from '@tanstack/react-query'
import { Api } from '../../../../lib/axios'

interface State {
  categoryId: string;
  nameKz: string;
}

const addSubCategory = async ({ nameKz, categoryId }: State): Promise<void> => {
  const response = await Api.post(`/categories/${categoryId}/subcategories`, { nameKz })

  return response.data
}

export const useAddSubCategoryMutation = () => {
  return useMutation({
    mutationFn: addSubCategory,
  })
}
