import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  nameKz: string;
  uri: string;
}

const addLink = async (state: State): Promise<void> => {
  const response = await Api.post('/links', state)

  return response.data
}

const addLinkMutationKey = 'addLink'

export const useAddLinkMutation = () => {
  return useMutation({
    mutationKey: [addLinkMutationKey],
    mutationFn: addLink,
  })
}
