import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table'
import type { Table as TableType } from '@tanstack/table-core'
import { flexRender } from '@tanstack/react-table'
import { DataTablePagination } from './data-table-pagination'
import { Loader2 } from 'lucide-react'

interface DataTableProps<T, U, R> {
  isLoading?: boolean
  table: TableType<T>
  isLead?: U
  handleUpdateStatus?: R
  handleSearchParams?: (name: string, id: string) => void
  isPagination?: boolean
}
export function DataTable<T, U, R>({ table, isLoading, isLead, handleUpdateStatus, handleSearchParams, isPagination = false, }: DataTableProps<T, U, R>) {
  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, {
                          ...header.getContext(),
                          isLead: isLead,
                        })}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length}>
                  <Loader2 className="animate-spin w-6 h-6 mx-auto" />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        isLead: isLead,
                        handleUpdateStatus: handleUpdateStatus,
                        handleSearchParams: handleSearchParams,
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
                  Дерек жоқ
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-3">
        {!isLoading && isPagination && <DataTablePagination table={table} />}
      </div>
    </>
  )
}
