import { AxiosResponse } from 'axios'
import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  id: number
}

const deleteUser = async ({id}: State): Promise<void> => {
  const response: AxiosResponse<any, any> = await Api.delete(`/application-users/${id}`)
  return response.data
}

export const deleteUserMutationKey = 'deleteUser'

export const useDeleteUserMutation = () => {
  return useMutation<void, unknown, State>({
    mutationKey: ['deleteUserMutationKey'],
    mutationFn: deleteUser,
  })
}
