import { PaginationResponse } from '../../../types/response'
import { UserType } from '../../../store/authStore'
import { Api } from '../../../lib/axios'
import { useQuery } from '@tanstack/react-query'

interface Response extends PaginationResponse {
  responseData: User[],
}

type User = {
  id: number
  email: string
  userType: UserType
}

const fetchUsers = async (pageIndex: number, pageSize: number): Promise<Response> => {
  const response = await Api.get(`/application-users?pageIndex=${pageIndex}&pageSize=${pageSize}`)
  return response.data
}

interface State {
  pageIndex: number
  pageSize: number;
}

export const usersQueryKey = 'users'

export const useUsersQuery = ({ pageIndex, pageSize }: State) => {
  return useQuery({
    queryKey: [usersQueryKey, pageIndex, pageSize],
    queryFn: () => fetchUsers(pageIndex, pageSize),
  })
}
