import { useQueryClient } from '@tanstack/react-query'
import { useDialogStore } from '../../../../store/dialogStore'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../../components/ui/dialog'
import { Button } from '../../../../components/ui/button'
import { useSharedStore } from '../../../../store/sharedStore'
import { useToast } from '../../../../components/ui/use-toast'
import { useDeleteSubCategoryItemMutation } from '../mutations/deleteSubCategoryItemMutation'
import { subCategoriesQueryKey } from '../queries/subCategories'
import { Api } from '../../../../lib/axios'


export default function DeleteSubCategoryDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const { mutateAsync, isPending, error } = useDeleteSubCategoryItemMutation()
  const subCategory = useSharedStore(state => state.selectedSubCategory)
  const { toast } = useToast()

  const handleDelete = async () => {
    await mutateAsync({ categoryId: subCategory?.parentCategoryId ?? '', subCategoryId: subCategory?.id ?? '' })
    void queryClient.invalidateQueries({ queryKey: [subCategoriesQueryKey] })
    toast({
      description: 'Санат өшірілді',
      variant: 'success',
    })
    setOpen(null)
  }

  return (
    <Dialog open={open === 'DeleteSubCategory'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Ішкі саннатты өшіру
          </DialogTitle>
          <DialogDescription>
            Ішкі санатты жойғыңыз келетініне сенімдісіз бе? <br/>
            Кейін бұл әрекетті кері қайтара алмайсыз.
          </DialogDescription>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-center text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <div className="flex w-full gap-3 mt-3">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setOpen(null)}
          >
            Жоқ
          </Button>
          <Button
            className="w-full"
            variant="destructive"
            loading={isPending}
            onClick={handleDelete}
          >
            Жою
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
