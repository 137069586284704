import { Document } from '../queries/documentsQuery'
import { z } from 'zod'
import { Control, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/ui/form'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { useEffect, useRef, useState } from 'react'
import { useUploadBlobMutation } from '../mutations/uploadBlobMutation'
import { ChevronDown, Eye, X } from 'lucide-react'
import { SubCategory, useSubCategoriesQuery } from '../../category/[id]/queries/subCategories'
import { useCategoriesQuery } from '../../category/queries/categories'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select'
import { cn } from '../../../lib/utils'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu'
import * as React from 'react'
import { ContentBlob } from '../mutations/createDocumentMutation'
import { Separator } from '../../../components/ui/separator'
import { Textarea } from '../../../components/ui/textarea'
import { useDialogStore } from '../../../store/dialogStore'

export const documentFormSchema = z.object({
  sourceContentBlob: z
    .custom<ContentBlob>()
    .refine((file) => !!file, { message: 'Толтыруға міндетті' }),
  filledContentBlob: z.custom<ContentBlob>().refine((file) => !!file, { message: 'Толтыруға міндетті' }).nullable(),
  nameKz: z.string({ required_error: 'Толтыруға міндетті' }),
  nameRu: z.string({ required_error: 'Толтыруға міндетті' }),
  parentSubcategories: z.array(
    z.object({
      id: z.string({ required_error: 'Толтыруға міндетті' }).min(1, { message: 'Толтыруға міндетті' }),
      subCategoryIds: z.array(z.number()).min(1, { message: 'Толтыруға міндетті' }),
    })
  ),
})

interface IProps {
  data: Document | null;
  onSubmit: (values: z.infer<typeof documentFormSchema>) => void
  submitTitle: string;
  loading?: boolean;
}
export default function DocumentForm ({data, submitTitle, onSubmit, loading}: IProps ) {
  const form = useForm<z.infer<typeof documentFormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(documentFormSchema),
    defaultValues: {
      sourceContentBlob: data?.sourceContentBlob,
      filledContentBlob: data?.filledSampleContentBlob ?? null,
      nameKz: data?.nameKz,
      nameRu: data?.nameRu,
      parentSubcategories: data?.parentSubcategories ? groupByCategory(data.parentSubcategories) : [{
        id: '',
        subCategoryIds: [],
      }],
    },
  })


  const open = useDialogStore((state) => state.open)

  useEffect(() => {
    if (open === 'CreateDocument') {
      form.reset();
    }
  }, [open])

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <FormSourceContentBlob />
          <FormFilledContentBlob />
          <FormField
            control={form.control}
            name="nameKz"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Қазақша атауы</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="nameRu"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Орысша атауы</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormParentSubcategories control={form.control} parentSubcategories={data?.parentSubcategories ?? []} />
          <Separator />
          <Button className="w-full" type="submit" loading={loading}>{submitTitle}</Button>
        </form>
      </FormProvider>
    </div>
  )
}

const FormSourceContentBlob = () => {
  const ref = useRef<HTMLInputElement>(null);

  const form = useFormContext()
  const { mutateAsync, data: blobData, isPending } = useUploadBlobMutation();

  const onRemove = (event: any) => {
    form.setValue('sourceContentBlob', null)
    event.preventDefault();
  }

  const onView = (uri: string) => {
    window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${uri}`, '_blank')
  }

  return (
    <FormField name="sourceContentBlob" control={form.control} render={({field}) => (
      <FormItem>
        <FormLabel>
          Құжат үлгісі .docx, .xls, .xlsx
        </FormLabel>

        <FormControl>
          <div className="flex flex-col">
            <div className="flex flex-col">
              {field.value?.name && (
                <div className="mb-2 flex items-center justify-between gap-3">
                  <span>{field.value?.name}</span>
                  <div className="flex items-center">
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        onView(field.value?.uri);
                      }}
                      className="rounded-full"
                      variant="ghost" size="sm">
                      <Eye className="w-4 h-4" />
                    </Button>
                    <Button onClick={onRemove} className="rounded-full" variant="ghost" size="sm">
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              )}
              <Input
                ref={ref}
                className="hidden"
                accept=".docx, .xls, .xlsx"
                type="file"
                onChange={async (event) => {
                  const file = event.target.files?.[0]
                  const formData = new FormData()

                  if (!file) return
                  formData.append('rq', file)
                  event.target.value = ''

                  const blobData = await mutateAsync({ rq: formData })
                  field.onChange( blobData.responseData)
                }}
              />
              <Button
                onClick={(event) => {
                  ref?.current?.click()
                  event.preventDefault()
                }}
                size="sm"
                variant="secondary"
                loading={isPending}
              >Таңдау</Button>
            </div>
          </div>
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
    />
  )
}

const FormFilledContentBlob = () => {
  const ref = useRef<HTMLInputElement>(null);

  const form = useFormContext()
  const { mutateAsync, data: blobData, isPending } = useUploadBlobMutation();

  const onRemove = (event: any) => {
    form.setValue('filledContentBlob', null)
    event.preventDefault();
  }

  const onView = (uri: string) => {
    window.open(uri, '_blank')
  }

  return (
    <FormField name="filledContentBlob" control={form.control} render={({field}) => {
      return (
        <FormItem>
          <FormLabel>
            Құжаттың толтырылған үлгісі .pdf
          </FormLabel>

          <FormControl>
            <div className="flex flex-col">
              <div className="flex flex-col">
                {field.value?.name && (
                  <div className="mb-2 flex items-center justify-between gap-3">
                    <span>{field.value?.name}</span>
                    <div className="flex items-center">
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          onView(field.value?.uri);
                        }}
                        className="rounded-full"
                        variant="ghost" size="sm">
                        <Eye className="w-4 h-4" />
                      </Button>
                      <Button onClick={onRemove} className="rounded-full" variant="ghost" size="sm">
                        <X className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                )}
                <Input
                  ref={ref}
                  className="hidden"
                  accept=".pdf"
                  type="file"
                  onChange={async (event) => {
                    const file = event.target.files?.[0]
                    const formData = new FormData()

                    if (!file) return
                    formData.append('rq', file)
                    event.target.value = ''

                    const blobData = await mutateAsync({ rq: formData })
                    field.onChange( blobData.responseData)
                  }} />
                <Button
                  onClick={(event) => {
                    ref?.current?.click()
                    event.preventDefault()
                  }}
                  size="sm"
                  variant="secondary"
                  loading={isPending}
                >Таңдау</Button>
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )
    }}
    />
  )
}

interface IFormParentSubcategoriesProps {
  control: Control<z.infer<typeof documentFormSchema>>
  parentSubcategories: SubCategory[];
}

const FormParentSubcategories = ({ control }: IFormParentSubcategoriesProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parentSubcategories',
  })

  const { data: categoriesData } = useCategoriesQuery();

  return (
    <div className="space-y-5">
      {fields.map((item, index) => (
        <div className="flex gap-3" key={index}>
          <FormField
            control={control}
            name={`parentSubcategories.${index}.id`}
            render={({ field }) => {
              const selectedCategory = categoriesData?.responseData?.find((item) => String(item.id) === field.value);
              return (
                <FormItem className="w-3/6">
                  <FormLabel>Құжат категориясын таңдаңыз</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Құжат категориясын таңдаңыз">{selectedCategory?.nameKz}</SelectValue>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {categoriesData?.responseData?.map((item, index) => (
                        <SelectItem key={index} value={String(item.id)}>
                          {item.nameKz}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )
            }}
          />

         <div className="w-3/6 flex gap-1">
           <FormSubCategory control={control} categoryIndex={index} />
           {index !== 0 && (
             <Button
               onClick={(event) => {
                 event.preventDefault()
                 remove(index)
               }}
               className="!w-6 !h-6 p-0 rounded-full my-auto mt-10"
               variant="ghost"
               size="sm"
             >
               <X className="w-4 h-4" />
             </Button>
           )}
         </div>
        </div>
      ))}
      <Button
        onClick={(event) => {
          event.preventDefault()
          append({
            id: '',
            subCategoryIds: []
          })
        }}
        className="!mt-1"
        variant="secondary"
        size="sm"
      >Категория қосу</Button>
    </div>
  )
}

const FormSubCategory = ({ control, categoryIndex  }: {
  control: Control<z.infer<typeof documentFormSchema>>
  categoryIndex: number
}) => {
  const form = useFormContext();
  const [isOpen, setOpen] = useState(false)

  const id = form.watch(`parentSubcategories.${categoryIndex}.id`);
  const subCategoryIds: number[] = form.watch(`parentSubcategories.${categoryIndex}.subCategoryIds`)
  const { data: subCategoriesData } = useSubCategoriesQuery(id);

  return (
    <FormField
      control={control}
      name={`parentSubcategories.${categoryIndex}.subCategoryIds`}
      render={({ field }) => {
        const selectedSubCategory = subCategoriesData?.responseData?.filter((item) => field.value.includes(item.id));

        return (
          <FormItem className="w-full">
            <FormLabel>Құжаттың ішкі категориясын таңдаңыз</FormLabel>

            <div onClick={() => setOpen(true)} className="cursor-pointer w-full border min-h-[40px] rounded-md flex justify-between items-center px-3 py-2">
              <div className="flex flex-wrap gap-1 cursor-pointer">
                {!id && (
                  <span className="text-sm">Құжаттың ішкі категориясын таңдаңыз</span>
                )}
                {selectedSubCategory?.map((item, index) => (
                  <div
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      const filteredSubCategoryIds = selectedSubCategory?.filter((subCategory) => subCategory.id !== item.id);
                      field.onChange(filteredSubCategoryIds?.map((item) => item.id));
                    }}
                    key={index}
                    className="w-fit flex items-center p-0.5 bg-secondary rounded-md gap-1">
                    <span className="text-xs">{item.nameKz}</span>
                    <X className="w-3.5 h-3.5 mt-1 shrink-0" />
                  </div>
                ))}
              </div>
              <ChevronDown className={cn('w-4 h-4 opacity-50 shrink-0 transition-all', {
                'transform rotate-180': isOpen
              })} />
            </div>

            <DropdownMenu open={isOpen} onOpenChange={setOpen}>
              <DropdownMenuTrigger asChild>
                <div></div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {subCategoriesData?.responseData?.map((item, index) => (
                  <DropdownMenuItem
                    key={index}
                    onSelect={() => {
                      const isSelected = subCategoryIds?.includes(item.id);

                      if (isSelected) {
                        field.onChange(subCategoryIds.filter((subCategoryId) => subCategoryId !== item.id));

                        return
                      }

                      field.onChange( [...subCategoryIds, item.id]);
                    }}
                    className={cn('cursor-pointer', {
                      'bg-primary/25 text-accent-foreground focus:bg-primary/25': field.value.includes(item.id)
                    })}
                  >
                    {item.nameKz}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

function groupByCategory(inputArray: SubCategory[]) {
  const result = [];

  // Create an object to store subcategories by category
  const categories: any = {};

  inputArray.forEach(subcategory => {
    const { parentCategoryId, id } = subcategory;

    // If the category does not exist in the object, create a new one
    if (!categories[parentCategoryId]) {
      categories[parentCategoryId] = {
        id: String(parentCategoryId),
        subCategoryIds: [],
      };
    }

    // Add the subcategory id to the corresponding category
    categories[parentCategoryId].subCategoryIds.push(id);
  });

  for (const categoryId in categories) {
    result.push(categories[categoryId]);
  }

  return result;
}
