import { useMutation } from '@tanstack/react-query'
import { Api } from '../../../../lib/axios'

interface State {
  categoryId: string | number;
  subcategoryIdsByDisplayOrder: number[];
}

const changeSubCategoryDisplayOrder = async ({ categoryId, subcategoryIdsByDisplayOrder }:State ) => {
  const response = await Api.patch(`/categories/${categoryId}/subcategories/display-order`, { subcategoryIdsByDisplayOrder });
  return response.data;
}

export const useSubCategoryChangeDisplayOrderMutation = () => {
  return useMutation({
    mutationFn: changeSubCategoryDisplayOrder,
  })
}
