import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  categoryIdsByDisplayOrder: number[];
}

const changeCategoryDisplayOrder = async ({ categoryIdsByDisplayOrder }:State ) => {
  const response = await Api.patch('/categories/display-order', { categoryIdsByDisplayOrder });
  return response.data;
}

export const useChangeDisplayOrderMutation = () => {
  return useMutation({
    mutationFn: changeCategoryDisplayOrder,
  })
}
