import { useEffect } from 'react'
import { useAuthStore } from '../../store/authStore'
import { useNavigate } from 'react-router-dom'


export default function HomePage() {
  const userRole = useAuthStore(state => state.userData?.userType)
  const navigate = useNavigate()

  useEffect(() => {
    if (!userRole) {
      navigate('/login')
      return
    }

    if (userRole?.includes('RootAdmin')) {
      navigate('/users')
    }

    navigate('/category')
  }, [])

  return (
    <div>
      <h1>Home Page</h1>
    </div>
  )
}
