import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Category } from '../pages/category/queries/categories'
import { SubCategory } from '../pages/category/[id]/queries/subCategories'
import { Link } from '../pages/links/queries/linksQuery'
import { Document } from '../pages/documents/queries/documentsQuery'

interface SharedState {
  selectedUser: {
    id: number;
    email: string;
  } | null
  setSelectedUser: (value: { id: number, email: string } | null) => void

  selectedCategory: Category | null
  setSelectedCategory: (value: Category | null) => void

  selectedSubCategory: SubCategory | null
  setSelectedSubCategory: (value: SubCategory | null) => void

  selectedLink: Link | null;
  setSelectedLink: (value: Link | null) => void;

  selectedDocument: Document | null;
  setSelectedDocument: (value: Document | null) => void;
}

export const useSharedStore = create<SharedState>()(
  devtools(
    (set) => ({
      selectedUser: null,
      setSelectedUser(value) {
        set({ selectedUser: value })
      },

      selectedCategory: null,
      setSelectedCategory(value) {
        set({ selectedCategory: value })
      },

      selectedSubCategory: null,
      setSelectedSubCategory(value) {
        set({ selectedSubCategory: value })
      },

      selectedLink: null,
      setSelectedLink(value) {
        set({ selectedLink: value })
      },

      selectedDocument: null,
      setSelectedDocument(value) {
        set({ selectedDocument: value })
      },
    }),
  ),
)
