import axios, { AxiosError } from 'axios'
import { useAuthStore } from '../store/authStore'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL

interface CustomErrorData {
  responseClientMessage?: string
}

export interface CustomError extends AxiosError<CustomErrorData> {}
axios.interceptors.request.use((config) => {
  const token = useAuthStore.getState().token

  if (token) {
    config.headers.Authorization = `${token}`
  }

  return config
})

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const logout = useAuthStore.getState().logout
    if (error.response?.status === 401) {
      logout()
    }

    return Promise.reject(error)
  },
)

export { axios as Api }
