import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from './pages/login/page'
import { MainLayout } from './layouts/MainLayout'
import Users from './pages/users/page'
import { Toaster } from './components/ui/toaster'
import CategoryPage from './pages/category/page'
import SubCategoryPage from './pages/category/[id]/page'
import DocumentsPage from './pages/documents/page'
import LinksPage from './pages/links/page'
import StatisticsPage from './pages/statistics/page'
import LogsPage from './pages/documentlogs/page'
import HomePage from './pages/home/page'

const queryClient = new QueryClient()
function App() {

  return (
    <div className="App overflow-hidden">
      <Toaster />

      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route element={<MainLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/users" element={<Users />} />
              <Route path="/category" element={<CategoryPage />} />
              <Route path="/category/:id" element={<SubCategoryPage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path="/links" element={<LinksPage />} />
              <Route path="/statistics" element={<StatisticsPage />} />
              <Route path="/documentlogs" element={<LogsPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  )
}

export default App
