import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { columns } from './components/columns'
import { DataTable } from '../../components/ui/data-table'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/queryParams'
import { useUsersQuery } from './queries/queries'
import { getPageCount } from '../../helpers/pagination'
import UserChangePasswordDialog from './components/UserChangePasswordDIalog'
import UserDeleteDialog from './components/UserDeleteDialog'
import UserCreateDialog from './components/UserCreateDialog'
import { Button } from '../../components/ui/button'
import { useDialogStore } from '../../store/dialogStore'


export default function Users() {
  const setOpen = useDialogStore(state => state.setOpen)
  const [searchParams, _] = useSearchParams()

  const page = Number(searchParams.get('page')) || DEFAULT_PAGE
  const size = Number(searchParams.get('size')) || DEFAULT_PAGE_SIZE

  const { data, isLoading } = useUsersQuery({ pageIndex: page, pageSize: size })

  const totalPages = getPageCount(data?.totalSize ?? 0, size)

  const table = useReactTable({
    data: data?.responseData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination: { pageSize: size, pageIndex: page },
    },
  })

  return (
    <section>
      <UserChangePasswordDialog />
      <UserDeleteDialog />
      <UserCreateDialog />
      <h2 className="text-left font-medium text-xl pb-6">Қолданушылар</h2>
      <div className="w-full flex mb-3">
        <Button onClick={() => setOpen('UserCreate')} className="ml-auto" size="sm">Қолданушы қосу</Button>
      </div>
      <DataTable table={table} isLoading={isLoading} isPagination={true} />
    </section>
  )
}
