import { useQueryClient } from '@tanstack/react-query'
import { useDialogStore } from '../../../../store/dialogStore'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../../components/ui/dialog'
import { useSharedStore } from '../../../../store/sharedStore'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useUpdateSubCategoryItemMutation } from '../mutations/updateSubCategoryItemMutation'
import { useToast } from '../../../../components/ui/use-toast'
import { Api } from '../../../../lib/axios'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../../components/ui/form'
import { Textarea } from '../../../../components/ui/textarea'
import { Button } from '../../../../components/ui/button'
import { subCategoriesQueryKey } from '../queries/subCategories'

const formSchema = z
  .object({
    nameKz: z.string({ required_error: 'Толтыруға міндетті' }).trim().max(120, { message: 'Атауы 120 символдан аса болмасын' }),
  })

export default function EditSubCategoryDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const subCategory = useSharedStore(state => state.selectedSubCategory);

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (subCategory) {
      form.setValue('nameKz', subCategory?.nameKz ?? '')
    }
  }, [subCategory])

  const { mutateAsync, error, isPending } = useUpdateSubCategoryItemMutation()
  const { toast } = useToast()

  const submit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync({ categoryId: subCategory?.parentCategoryId ?? '', subCategoryId: subCategory?.id ?? '', ...data })
    void queryClient.invalidateQueries({ queryKey: [subCategoriesQueryKey] })
    toast({
      description: 'Ішкі санат өзгертілді',
      variant: 'success',
    })
    setOpen(null)
  }

  return (
    <Dialog open={open === 'EditSubCategory'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Ішкі саннатты өзгерту
          </DialogTitle>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)} className="space-y-5">
            <FormField
              control={form.control}
              name="nameKz"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ішкі санат атауы</FormLabel>
                  <FormControl>
                    <Textarea maxLength={120} placeholder="Ішкі санат атауын енгізіңіз" {...field}  />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className="w-full"
              type="submit"
              loading={isPending}
            >
              Сақтау
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
