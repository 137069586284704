import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface LayoutState {
  sidebarSize: number;
  setSidebarSize: (size: number) => void;
}

export const useLayoutStore = create<LayoutState>()(
  devtools(
    persist(
      (set) => ({
        sidebarSize: 20,
        setSidebarSize(size: number) {
          set({sidebarSize: size})
        }
      }),
      {
        name: 'layout-storage',
      },
    ),
  ),
)
