import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  id: number;
  nameKz: string;
  iconBlobId: string;
}

const updateCategoryItem = async ({ id, ...rest }: State): Promise<void> => {
  const response = await Api.patch(`/categories/${id}`, { ...rest });

  return response.data;
}

export const updateCategoryItemMutationKey = 'updateCategoryItemMutationKey';

export const useUpdateCategoryItemMutation = () => {
  return useMutation({
    mutationKey: [updateCategoryItemMutationKey],
    mutationFn: updateCategoryItem,
  })
}
