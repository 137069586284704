import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog'
import { useDialogStore } from '../../../store/dialogStore'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from '../../../components/ui/button'
import { useSharedStore } from '../../../store/sharedStore'
import { useToast } from '../../../components/ui/use-toast'
import { useDeleteLinkMutation } from '../mutations/deleteLinkMutation'
import { linksQueryKey } from '../queries/linksQuery'
import { Api } from '../../../lib/axios'

export default function DeleteLinkDialog () {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const { mutateAsync, isPending, error } = useDeleteLinkMutation()
  const link = useSharedStore(state => state.selectedLink)
  const { toast } = useToast()

  const handleDelete = async () => {
    await mutateAsync({ id: link?.id as number })
    void queryClient.invalidateQueries({ queryKey: [linksQueryKey] })
    toast({
      description: 'Нормативтік құқықты өшірілді',
      variant: 'success',
    })
    setOpen(null)
  }

  return (
    <Dialog open={open === 'DeleteLink'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Нормативтiк құқықты өшіру
          </DialogTitle>
          <DialogDescription>
            Жойғыңыз келетініне сенімдісіз бе? <br/>
            Бұл әрекетті кері қайтара алмайсыз.
          </DialogDescription>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-center text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <div className="flex w-full gap-3 mt-3">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setOpen(null)}
          >
            Жоқ
          </Button>
          <Button
            className="w-full"
            variant="destructive"
            loading={isPending}
            onClick={handleDelete}
          >
            Жою
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
