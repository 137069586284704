import { Button } from '../../components/ui/button'
import { Loader2, Pencil, RotateCcw, Save, Trash } from 'lucide-react'
import AddCategoryDialog from './components/AddCategoryDialog'
import { useDialogStore } from '../../store/dialogStore'
import { Category, useCategoriesQuery } from './queries/categories'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useMemo, useState } from 'react'
import { useChangeDisplayOrderMutation } from './mutations/changeDisplayOrderMutation'
import * as React from 'react'
import EditCategoryDialog from './components/EditCategoryDialog'
import { useSharedStore } from '../../store/sharedStore'
import DeleteCategoryDialog from './components/DeleteCategoryDialog'
import { useNavigate } from 'react-router-dom'

export default function CategoryPage() {
  const navigate = useNavigate()

  const setOpen = useDialogStore((state) => state.setOpen)

  const { data, isLoading, refetch, isFetching } = useCategoriesQuery()
  const [items, setItems] = useState<Category[]>(data?.responseData ?? []);

  useEffect(() => {
    if (data?.responseData) {
      setItems(data.responseData)
    }
  }, [data])


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const newItems = Array.from(items ?? []);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    newItems[result.source.index].displayOrder = result.source.index + 1;
    newItems[result.destination.index].displayOrder = result.destination.index + 1;

    setItems(newItems);
  };

  const handleReset = () => {
    void refetch()
  }

  const isCategoryPositionEqual = useMemo(() => {
    return JSON.stringify(data?.responseData) === JSON.stringify(items)
  }, [data, items])

  const { mutateAsync, isPending: isMutationPending } = useChangeDisplayOrderMutation()

  const handleSave = async () => {
    const categoryIdsByDisplayOrder = items?.map((item) => item.id);
    await mutateAsync({ categoryIdsByDisplayOrder })
    void refetch()
  }

  const setCategory = useSharedStore(state => state.setSelectedCategory)
  const handleEdit = (category: Category) => {
    setCategory(category)
    setOpen('EditCategory')
  }

  const handleDelete = (category: Category) => {
    setCategory(category)
    setOpen('DeleteCategory')
  }

  return (
    <section className="pb-10">
      <AddCategoryDialog />
      <EditCategoryDialog />
      <DeleteCategoryDialog />

      <h2 className="text-left font-medium text-xl pb-6">Тақырыптық санат</h2>

      <div className="flex justify-end mb-4">
        {!isCategoryPositionEqual && !isFetching && !isLoading && (
          <Button loading={isMutationPending} onClick={handleSave} variant="ghost" size="sm">
            <Save className="w-5 h-5" />
          </Button>
        )}
        {!isLoading && !isFetching && (
          <Button loading={isLoading || isFetching} className="mr-2" onClick={handleReset} variant="ghost" size="sm">
            <RotateCcw className="w-5 h-5" />
          </Button>
        )}
        <Button onClick={() => setOpen('AddCategory')} size="sm">Қосу</Button>
      </div>

      {isFetching || isLoading ? (
        <div className="w-full h-[600px]">
          <Loader2 className="animate-spin w-6 h-6 mx-auto" />
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((category, index) => (
                  <Draggable key={category.id} index={index} draggableId={`item-${category.id}`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="w-full bg-white min-h-[65px] flex justify-between items-center p-4 rounded-lg cursor-pointer hover:shadow border mb-4"
                      >
                        <div className="flex items-center">
                          <img className="w-7 h-7 shrink-0 grow mr-4" src={category?.iconBlob?.uri ?? ''} alt="icon" />
                          <div className="text-base font-medium max-w-[450px]">{category.nameKz}</div>
                        </div>

                        <div className="flex">
                          <div className="flex gap-2 mr-4">
                            <Button onClick={() => handleEdit(category)} variant="ghost" size="sm">
                              <Pencil className="w-4 h-4" />
                            </Button>

                            <Button onClick={() => handleDelete(category)} variant="ghost" size="sm">
                              <Trash className="w-4 h-4" />
                            </Button>
                          </div>

                          <Button onClick={() => navigate(`/category/${category.id}`)} className="text-primary" variant="ghost" size="sm">
                            Толығырақ
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </section>
  )
}
