import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import { useDialogStore } from '../../../store/dialogStore'
import DocumentForm, { documentFormSchema } from './DocumentForm'
import { useDocumentByIdQuery } from '../queries/documentByIdQuery'
import { CreateDocumentState, useCreateDocumentMutation } from '../mutations/createDocumentMutation'
import { z } from 'zod'
import { useToast } from '../../../components/ui/use-toast'
import { useQueryClient } from '@tanstack/react-query'
import { DOCUMENTS_QUERY_KEY } from '../queries/documentsQuery'
import { useEffect } from 'react'


export default function CreateDocumentDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore(state=> state.open)
  const setOpen = useDialogStore(state=> state.setOpen)

  const { mutateAsync, isPending } = useCreateDocumentMutation();
  const { toast } = useToast()

  const onSubmit = async (data: z.infer<typeof documentFormSchema>) => {
    const formattedData = formatData(data)

    await mutateAsync(formattedData)
    void queryClient.invalidateQueries({
      queryKey: [DOCUMENTS_QUERY_KEY]
    })
    setOpen(null)
    toast({
      title: 'Құжат сәтті жасалды',
      variant: 'success',
    })
  }

  return (
    <Dialog open={open === 'CreateDocument'} onOpenChange={() => setOpen(null)}>
      <DialogContent className="max-w-[750px] max-h-[800px] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Құжат қосу</DialogTitle>
          <DialogDescription>
            Құжатты жасау үшін деректерді енгізіңіз
          </DialogDescription>
        </DialogHeader>

        <DocumentForm data={null} onSubmit={onSubmit} loading={isPending} submitTitle="Қосу" />
      </DialogContent>
    </Dialog>
  )
}

const formatData = (data: z.infer<typeof documentFormSchema>): CreateDocumentState => {
  const formattedData: CreateDocumentState = {
    nameKz: data.nameKz,
    nameRu: data.nameRu,
    sourceContentBlobId: data.sourceContentBlob.id,
    filledContentBlobId: data?.filledContentBlob?.id,
    parentSubcategoryIds: [],
  }
  data.parentSubcategories.map((category) => category.subCategoryIds.map(subCategory => formattedData.parentSubcategoryIds.push(subCategory)))

  return formattedData
}
