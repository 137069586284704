import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/queryParams'
import { useDocumentsQuery } from './queries/documentsQuery'
import { getPageCount } from '../../helpers/pagination'
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { columns } from './components/columns'
import { DataTable } from '../../components/ui/data-table'
import { useEffect, useState } from 'react'
import { Input } from '../../components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select'
import { Button } from '../../components/ui/button'
import { useDebounce, useIsFirstRender } from 'usehooks-ts'
import { useCategoriesQuery } from '../category/queries/categories'
import { useSubCategoriesQuery } from '../category/[id]/queries/subCategories'
import CreateDocumentDialog from './components/CreateDocumentDialog'
import { useDialogStore } from '../../store/dialogStore'
import EditDocumentDialog from './components/EditDocumentDialog'
import DeleteDocumentDialog from './components/DeleteDocumentDialog'


export default function DocumentsPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page')) || DEFAULT_PAGE
  const size = Number(searchParams.get('size')) || DEFAULT_PAGE_SIZE
  const sortProperty = searchParams.get('sortProperty');
  const sortOrder = searchParams.get('sortOrder');
  const nameSearchPattern = searchParams.get('nameSearchPattern');
  const parentCategoryIds = searchParams.get('parentCategoryIds');
  const parentSubcategoryIds = searchParams.get('parentSubcategoryIds');

  const { data, isLoading, isFetching } = useDocumentsQuery({ pageIndex: page, pageSize: size, sortProperty, sortOrder, nameSearchPattern, parentCategoryIds, parentSubcategoryIds })
  const [sorting, setSorting] = React.useState<SortingState>([])
  const totalPages = getPageCount(data?.totalSize ?? 0, size)

  const table = useReactTable({
    data: data?.responseData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableSorting: true,
    state: {
      pagination: { pageSize: size, pageIndex: page },
      sorting: [...(sortOrder && sortProperty ? [{ id: sortProperty, desc: sortOrder === 'Descending' }] : [])]
    },
  })

  useEffect(() => {
    if (sorting.length > 0) {
      const { id, desc } = sorting[0]
      searchParams.set('sortProperty', id)
      searchParams.set('sortOrder', desc ? 'Descending' : 'Ascending')
      setSearchParams(searchParams)
    }
  }, [sorting])


  const setOpen = useDialogStore(state => state.setOpen)

  return (
    <section className="pb-10">
      <CreateDocumentDialog />
      <EditDocumentDialog />
      <DeleteDocumentDialog />

      <h2 className="text-left font-medium text-xl pb-6">Құжат үлгілері</h2>

      <div className="mb-3 w-full flex justify-between">
        <Filters />
        <Button onClick={() => setOpen('CreateDocument')} size="sm">Қосу</Button>
      </div>

      <DataTable table={table} isLoading={isLoading || isFetching} isPagination={true} />
    </section>
  )
}

const Filters = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const nameSearchPattern = searchParams.get('nameSearchPattern');
  const parentCategoryIds = searchParams.get('parentCategoryIds');
  const parentSubcategoryIds = searchParams.get('parentSubcategoryIds');

  const isFirst = useIsFirstRender()
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue, 500)

  useEffect(() => {
    if (nameSearchPattern) {
      setSearchValue(nameSearchPattern)
    }
  }, [])

  useEffect(() => {
    if (!isFirst) {
      searchParams.set('nameSearchPattern', debouncedValue);
      searchParams.set('page', '0');
      setSearchParams(searchParams);
    }
  }, [debouncedValue])

  const onClearFilter = () => {
    searchParams.delete('nameSearchPattern');
    searchParams.delete('parentCategoryIds');
    searchParams.delete('parentSubcategoryIds');
    setSearchParams(searchParams);
  }

  return (
    <div className="flex gap-3 basis-5/6">
      <Input className="max-w-[300px]" value={searchValue} onChange={(event) => setSearchValue(event.target.value)} placeholder="Құжаттың атын еңгізіңіз" />
      <CategoryFilter />
      <SubCategoryFilter />
      {(nameSearchPattern || parentCategoryIds || parentSubcategoryIds) && <Button onClick={onClearFilter} variant="outline" size="sm" className="h-[40px]">
        Фильтрлерді тазалау
      </Button>}
    </div>
  )
}

const CategoryFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const parentCategoryIds = searchParams.get('parentCategoryIds');
  const { data } = useCategoriesQuery()

  const onChange = (value: string) => {
    searchParams.set('parentCategoryIds', value);
    setSearchParams(searchParams);
  }

  return (
      <Select onValueChange={onChange} value={parentCategoryIds ?? ''}>
        <SelectTrigger className="max-w-[220px]">
          <SelectValue placeholder="Санат" />
        </SelectTrigger>

        <SelectContent>
          {data?.responseData?.map((category) => (
            <SelectItem value={String(category.id)}>{category?.nameKz}</SelectItem>
          ))}
        </SelectContent>
      </Select>
  )
}

const SubCategoryFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const parentCategoryIds = searchParams.get('parentCategoryIds');
  const parentSubcategoryIds = searchParams.get('parentSubcategoryIds');

  const { data } = useSubCategoriesQuery(parentCategoryIds ?? '')

  const onChange = (value: string) => {
    searchParams.set('parentSubcategoryIds', value);
    setSearchParams(searchParams);
  }

  if (!parentCategoryIds) {
    return null
  }

  return (
    <Select onValueChange={onChange} value={parentSubcategoryIds ?? ''}>
      <SelectTrigger className="max-w-[220px]">
        <SelectValue placeholder="Ішкі санат" />
      </SelectTrigger>

      <SelectContent>
        {data?.responseData?.map((subCategory) => (
          <SelectItem value={String(subCategory.id)}>{subCategory?.nameKz}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
