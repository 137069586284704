import { ContentBlob } from './createDocumentMutation'
import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  rq: any;
}
interface Response {
  responseData: ContentBlob;
}

const uploadBlob = async ({ rq }: State): Promise<Response> => {
  const response = await Api.post('/blobs', rq);

  return response.data;
}

const UPLOAD_BLOB_MUTATION_KEY = 'uploadBlobMutation';

export const useUploadBlobMutation = () => {
  return useMutation({
    mutationKey: [UPLOAD_BLOB_MUTATION_KEY],
    mutationFn: uploadBlob,
  })
}
