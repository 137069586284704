import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  nameKz: string;
  iconBlobId: string;
}

const addCategory = async (data: State): Promise<void> => {
  const response = await Api.post('/categories', data)

  return response.data
}

export const useCategoryMutation = () => {
  return useMutation({
    mutationFn: addCategory,
  })
}
