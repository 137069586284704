
import { useFormContext } from 'react-hook-form'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/ui/form'
import { Input } from '../../../components/ui/input'

export default function LinkForm() {
  const form = useFormContext()

  return (
    <div className="space-y-5">
      <FormField
        control={form.control}
        name="nameKz"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Нормативтiк құқықтық актiнің атау</FormLabel>
            <FormControl>
              <Input maxLength={60} placeholder="Нормативтiк құқықтық актiнің атауын енгізіңіз" {...field}  />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="uri"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Нормативтік құжаттама веб-сайтына сілтеме</FormLabel>
            <FormControl>
              <Input maxLength={60} placeholder="Нормативтік құжаттама веб-сайтына сілтеме" {...field}  />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
