import { Api } from '../../../lib/axios'
import { useQuery } from '@tanstack/react-query'
import { Document } from './documentsQuery'

interface State {
  id?: number | undefined;
}

interface Response {
  responseData: Document;
}

const fetchDocumentById = async ({ id }: State): Promise<Response> => {
  const response = await Api.get(`/documents/${id}`)

  return response.data
}

export const DOCUMENT_BY_ID_QUERY_KEY = 'documentByIdQuery'

export const useDocumentByIdQuery = ({ id }: State) => {
  return useQuery({
    queryKey: [DOCUMENT_BY_ID_QUERY_KEY, id],
    queryFn: () => fetchDocumentById({ id }),
    enabled: !!id,
  })
}
