import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'


const changePassword = async ({id, password}: State): Promise<void> => {
  const response: AxiosResponse<any, any> = await Api.patch(`/application-users/${id}/password`, { password })
  return response.data
}

interface State {
  id: number
  password: string
}

export const changePasswordMutationKey = 'changePassword'

export const useChangePasswordMutation = () => {
  return useMutation<void, unknown, State>({
    mutationKey: ['changePasswordMutationKey'],
    mutationFn: changePassword,
  })
}
