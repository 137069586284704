import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

export interface EditDocumentState {
  id: string;
  nameKz: string;
  nameRu: string;
  parentSubcategoryIds: number[];
  sourceContentBlobId: number;
  filledContentBlobId?: number;
}

const editDocument = async ({ id, nameKz, nameRu, parentSubcategoryIds, sourceContentBlobId, filledContentBlobId }: EditDocumentState): Promise<Response> => {
  const response = await Api.patch(`/documents/${id}`, {
    nameKz,
    nameRu,
    parentSubcategoryIds,
    sourceContentBlobId,
    filledContentBlobId
  })

  return response.data
}

const EDIT_DOCUMENTS_MUTATION_KEY = 'editDocumentsMutation'

export const useEditDocumentMutation = () => {
  return useMutation({
    mutationKey: [EDIT_DOCUMENTS_MUTATION_KEY],
    mutationFn: editDocument,
  })
}
