import * as React from 'react'
import { useLinksQuery } from './queries/linksQuery'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/queryParams'
import { useSearchParams } from 'react-router-dom'
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { columns } from './components/columns'
import { getPageCount } from '../../helpers/pagination'
import { DataTable } from '../../components/ui/data-table'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { useEffect, useState } from 'react'
import { useDebounce, useIsFirstRender } from 'usehooks-ts'
import AddLinkDialog from './components/AddLinkDIalog'
import { useDialogStore } from '../../store/dialogStore'
import EditLinkDialog from './components/EditLinkDialog'
import DeleteLinkDialog from './components/DeleteLinkDialog'

export default function LinksPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page')) || DEFAULT_PAGE
  const size = Number(searchParams.get('size')) || DEFAULT_PAGE_SIZE
  const nameSearchPattern = searchParams.get('nameSearchPattern');

  const { data, isFetching } = useLinksQuery({pageIndex: page, pageSize: size, nameSearchPattern })
  const totalPages = getPageCount(data?.totalSize ?? 0, size)

  const table = useReactTable({
    data: data?.responseData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    state: {
      pagination: { pageSize: size, pageIndex: page },
    },
  })

  const isFirst = useIsFirstRender()
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue, 500)

  useEffect(() => {
    if (nameSearchPattern) {
      setSearchValue(nameSearchPattern)
    }
  }, [])

  useEffect(() => {
    if (!isFirst) {
      searchParams.set('nameSearchPattern', debouncedValue);
      searchParams.set('page', '0');
      setSearchParams(searchParams);
    }
  }, [debouncedValue])

  const setOpen = useDialogStore(state => state.setOpen)

  return (
    <section className="pb-10">
      <AddLinkDialog />
      <EditLinkDialog />
      <DeleteLinkDialog />

      <h2 className="text-left font-medium text-xl pb-6">Нормативтiк құқықтық актiлер</h2>

      <div className="mb-3 w-full flex justify-between">
        <Input className="max-w-[300px]" value={searchValue} onChange={(event) => setSearchValue(event.target.value)} placeholder="Нормативтың атын еңгізіңіз" />

        <Button onClick={() => setOpen('AddLink')} size="sm">Қосу</Button>
      </div>

      <DataTable table={table} isLoading={isFetching} isPagination={true} />
    </section>
  )
}
