import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

export interface CreateDocumentState {
  nameKz: string;
  nameRu: string;
  parentSubcategoryIds: number[];
  sourceContentBlobId: number;
  filledContentBlobId?: number;
}

export interface ContentBlob {
  id: number;
  name: string;
  extension: string;
  mimeType: string;
  uri: string;
}

const createDocument = async ({ nameKz, nameRu, parentSubcategoryIds, sourceContentBlobId, filledContentBlobId }: CreateDocumentState): Promise<Response> => {
  const response = await Api.post('/documents', {
    nameKz,
    nameRu,
    parentSubcategoryIds,
    sourceContentBlobId,
    filledContentBlobId
  })

  return response.data
}

const CREATE_DOCUMENTS_MUTATION_KEY = 'createDocumentsMutation'

export const useCreateDocumentMutation = () => {
  return useMutation({
    mutationKey: [CREATE_DOCUMENTS_MUTATION_KEY],
    mutationFn: createDocument,
  })
}
