import { PaginationResponse } from '../../../types/response'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Api } from '../../../lib/axios'

interface Response extends PaginationResponse {
  responseData: Log[]
}

export type Log = {
  id: number;
  actionType: ActionType;
  searchDataColumn: string;
  actionByUserId: number;
  actionByUserEmail: string;
  eventMoment: Date;
}

export enum ActionType {
  Created = 'Created',
  Modified = 'Modified',
  Removed = 'Removed',
}

interface State {
  pageIndex: number;
  pageSize: number;
  nameSearchPattern?: string | null;
  sortProperty?: string | null;
  sortOrder?: string | null;
}

const fetchLogs = async ({ pageIndex, pageSize, nameSearchPattern, sortProperty, sortOrder }: State): Promise<Response> => {
  const response = await Api.get('/documents/changed-events', {
    params: {
      pageIndex,
      pageSize,
      ...(nameSearchPattern ? { nameSearchPattern } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      ...(sortOrder ? { sortOrder } : {}),
    }
  })

  return { responseData: response.data, ...response.data }
}

const LOGS_QUERY_KEY = 'documentlogs'

export const useLogsQuery = ({ pageIndex, pageSize, nameSearchPattern, sortProperty, sortOrder }: State) => {
  return useQuery({
    queryKey: [LOGS_QUERY_KEY, { pageIndex, pageSize, nameSearchPattern, sortProperty, sortOrder }],
    queryFn: () => fetchLogs({ pageIndex, pageSize, nameSearchPattern, sortProperty, sortOrder }),
    placeholderData: keepPreviousData
  })
}
