import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../../components/ui/dialog'
import { useDialogStore } from '../../../../store/dialogStore'
import { useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAddSubCategoryMutation } from '../mutations/addSubCategoryMutation'
import { useToast } from '../../../../components/ui/use-toast'
import { categoriesQueryKey } from '../../queries/categories'
import { useParams } from 'react-router-dom'
import { subCategoriesQueryKey } from '../queries/subCategories'
import { useEffect } from 'react'
import { Api } from '../../../../lib/axios'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../../components/ui/form'
import { Textarea } from '../../../../components/ui/textarea'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components/ui/select'
import { cn } from '../../../../lib/utils'
import { Button } from '../../../../components/ui/button'

const formSchema = z
  .object({
    nameKz: z.string({ required_error: 'Толтыруға міндетті' }).trim().max(120, { message: 'Атауы 120 символдан аса болмасын' })
  })

export default function AddSubCategoryDialog() {
  const queryClient = useQueryClient()
  const { id } = useParams();

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {},
  })

  const { mutateAsync, error, isPending } = useAddSubCategoryMutation()
  const { toast } = useToast()

  const submit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync({ ...data, categoryId: id ?? '' })
    void queryClient.invalidateQueries({ queryKey: [subCategoriesQueryKey] })
    toast({
      description: 'Санат қосылды',
      variant: 'success',
    })
    setOpen(null)
  }

  useEffect(() => {
    form.reset();
  }, [open])

  return (
    <Dialog open={open === 'AddSubCategory'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Жаңа санат қосу
          </DialogTitle>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)} className="space-y-5">
            <FormField
              control={form.control}
              name="nameKz"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Ішкі санат атауы
                  </FormLabel>
                  <FormControl>
                    <Textarea maxLength={120} placeholder="Ішкі санат атауын енгізіңіз" {...field}  />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className="w-full"
              type="submit"
              loading={isPending}
            >
              Сақтау
            </Button>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}
