import { ColumnDef } from "@tanstack/react-table"
import { Button } from '../../../components/ui/button'
import { Pencil, Trash } from 'lucide-react'
import { Link as TLink } from '../queries/linksQuery'
import { Link } from 'react-router-dom'
import { useDialogStore } from '../../../store/dialogStore'
import { useSharedStore } from '../../../store/sharedStore'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../components/ui/tooltip'
import * as React from 'react'

export const columns: ColumnDef<TLink>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "nameKz",
    header: 'Нормативтік құқықтық актінің атауы'
  },
  {
    accessorKey: 'links',
    header: 'Сілтеме',
    cell: props => {
      const url = props.row.original.uri
      return (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Link to={url} target="_blank">
                <Button variant="link" size="sm">Сілтеме</Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p>{url}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }
  },
  {
    accessorKey: "actions",
    header: 'Әрекет',
    cell: (props) => {
      const setOpen = useDialogStore.getState().setOpen;
      const setLink = useSharedStore.getState().setSelectedLink

      const data = props.row.original

      const handleEdit = () => {
        setLink(data);
        setOpen('EditLink')
      }

      const handleDelete = () => {
        setLink(data);
        setOpen('DeleteLink')
      }

      return (
        <span className="flex gap-1">
          <Button onClick={handleEdit} className="rounded-full" size="sm" variant="ghost">
            <Pencil className="w-4 h-4" />
          </Button>

          <Button onClick={handleDelete} className="rounded-full" size="sm" variant="ghost">
            <Trash className="w-4 h-4" />
          </Button>
        </span>
      )
    }
  },
]
