import { useMutation } from '@tanstack/react-query'
import { Api } from '../../../../lib/axios'


interface State {
  categoryId: string | number;
  subCategoryId: string | number;
}

const deleteSubCategoryItem = async ({ categoryId, subCategoryId }: State): Promise<void> => {
  const response = await Api.delete(`/categories/${categoryId}/subcategories/${subCategoryId}`);
  return response.data;
}

export const deleteSubCategoryItemMutationKey = 'deleteSubCategoryItem';

export const useDeleteSubCategoryItemMutation = () => {
  return useMutation({
    mutationKey: [deleteSubCategoryItemMutationKey],
    mutationFn: deleteSubCategoryItem,
  })
}

