import { PaginationResponse } from '../../../types/response'
import { Api } from '../../../lib/axios'
import { useQuery } from '@tanstack/react-query'

interface Response extends PaginationResponse {
  responseData: Icon[]
}

type Icon = {
  id: string;
  name: string;
  uri: string;
}

const fetchCategoryIcons = async (): Promise<Response> => {
  const response = await Api.get('/icons?pageIndex=0&pageSize=0');

  return response.data;
}

export const categoryIconsQueryKey = 'categoryIconsQueryKey';

export const useCategoryIconsQuery = () => {
  return useQuery({
    queryKey: [categoryIconsQueryKey],
    queryFn: fetchCategoryIcons,
  })
}
