import { DatePicker } from '../../components/ui/date-picker'
import { DateRange } from 'react-day-picker'
import { useEffect, useMemo, useState } from 'react'
import { format, startOfDay, subDays } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { useCategoriesQuery } from '../category/queries/categories'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select'
import { useSubCategoriesQuery } from '../category/[id]/queries/subCategories'
import { useDocumentsQuery } from '../documents/queries/documentsQuery'
import { Statistics, useStatisticsQuery } from './queries/statisticsQuery'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar,  } from 'react-chartjs-2';
import { useWindowSize } from 'usehooks-ts'
import { log10 } from 'chart.js/helpers'
import { Button } from '../../components/ui/button'
import {
  useDownloadStatisticsType1Mutation,
  useDownloadStatisticsType2Mutation, useDownloadStatisticsType3Mutation,
} from './mutations/downloadStatisticsMutation'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DATE_FROM = subDays(startOfDay(new Date()), 7);
const DATE_TO = new Date();

export default function StatisticsPage() {
  const [searchParams] = useSearchParams()

  const dateFrom = new Date(searchParams.get('dateFrom') ?? DATE_FROM)
  const dateTo = new Date(searchParams.get('dateTo') ?? DATE_TO)
  const categoryId = searchParams.get('categoryId')
  const subCategoryId = searchParams.get('subCategoryId')
  const documentId = searchParams.get('documentId')

  const { data } = useStatisticsQuery({ from: dateFrom, to: dateTo, categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '' })

  const [axis, setAxis] = useState<'x' | 'y'>('x')

  const { width, height } = useWindowSize()

  useEffect(() => {
    if (width >= 768) {
      setAxis('x')
    } else {
      setAxis('y')
    }
  }, [width])

  const labels = useMemo(() => {
    if (!data?.responseData) {
      return []
    }

    return data.responseData.map((item) => format(item.day, 'dd.MM.yyyy'))
  }, [data])

  const options = {
    indexAxis: axis,
    responsive: true,
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Жүктелініп алынған құжаттар саны',
        data: data?.responseData.map(item => item.totalDownloadedDocuments),
        backgroundColor: 'rgb(201, 203, 207)',
      },
      {
        label: 'Қаралған құжаттар саны',
        data: data?.responseData.map(item => item.totalPreviewedDocuments),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Жүктелген құжат құжаттар саны',
        data: data?.responseData.map(item => item.totalUploadedDocuments),
        backgroundColor: 'rgb(75, 192, 192)',
      },
    ]
  }

  const { mutate: downloadStatisticsType1, isPending: isPending1 } = useDownloadStatisticsType1Mutation();
  const { mutate: downloadStatisticsType2, isPending: isPending2 } = useDownloadStatisticsType2Mutation();
  const { mutate: downloadStatisticsType3, isPending: isPending3 } = useDownloadStatisticsType3Mutation();

  return (
    <section className="pb-10">
      <h2 className="text-left font-medium text-xl pb-6">Статистика</h2>

      <Filters />

      {axis === 'y' && (
        <Bar options={options} data={chartData} />
      )}
`
      {axis === 'x' && (
        <Bar options={options} data={chartData} />
      )}

      <div className="flex flex-col gap-3 max-w-[200px] mt-6">
        <Button size="sm" onClick={() => downloadStatisticsType1()} loading={isPending1}>Есеп 1</Button>
        <Button size="sm" onClick={() => downloadStatisticsType2()} loading={isPending2}>Есеп 2</Button>
        <Button size="sm" onClick={() => downloadStatisticsType3()} loading={isPending3}>Есеп 3</Button>
      </div>
    </section>
  )
}

const Filters = () => {
  return (
    <div className="mb-3 w-full">
       <div className="mb-3">
         <DateFilter />
       </div>

      <div className="flex gap-3">
        <CategoriesFilter />
        <SubCategoryFilter />
        <DocumentFilter />
      </div>
    </div>
  )
}

const DateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const dateFrom = searchParams.get('dateFrom')
  const dateTo = searchParams.get('dateTo')

  const dateRange = useMemo(() => {
    return {
      from: dateFrom ? new Date(dateFrom) : DATE_FROM,
      to: dateTo ? new Date(dateTo) : DATE_TO
    }
  }, [dateFrom, dateTo])

  const onChange = (value: DateRange | undefined ) => {
    if (!value) {
      return
    }

    searchParams.set('dateFrom', value.from?.toISOString() ?? '');
    searchParams.set('dateTo', value.to?.toISOString() ?? '');
    setSearchParams(searchParams);
  }

  return (
    <DatePicker className="max-w-[300px]" onChange={onChange} date={dateRange} />
  )
}

const CategoriesFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const categoryId = searchParams.get('categoryId')
  const { data: categories } = useCategoriesQuery()

  const onChange = (value: string) => {
    if (value === 'empty') {
      searchParams.delete('categoryId');
      searchParams.delete('subCategoryId');
      searchParams.delete('documentId');
      setSearchParams(searchParams);

      return;
    }

    searchParams.set('categoryId', value);
    searchParams.delete('subCategoryId');
    searchParams.delete('documentId');
    setSearchParams(searchParams);
  }

  return (
    <Select onValueChange={onChange} value={categoryId ?? ''}>
      <SelectTrigger className="max-w-[300px]">
        <SelectValue placeholder="Санат" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="empty">-</SelectItem>
        {categories?.responseData?.map((category) => (
          <SelectItem value={String(category.id)}>{category?.nameKz}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const SubCategoryFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const categoryId = searchParams.get('categoryId')
  const subCategoryId = searchParams.get('subCategoryId')
  const { data: subCategories } = useSubCategoriesQuery(categoryId ?? '')

  const onChange = (value: string) => {
    if (value === 'empty') {
      searchParams.delete('subCategoryId');
      searchParams.delete('documentId');
      setSearchParams(searchParams);
      return;
    }

    searchParams.set('subCategoryId', value);
    searchParams.delete('documentId')
    setSearchParams(searchParams);
  }

  if (!categoryId) {
    return null
  }

  return (
    <Select onValueChange={onChange} value={subCategoryId ?? ''}>
      <SelectTrigger className="max-w-[300px]">
        <SelectValue placeholder="Санат" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="empty">-</SelectItem>
        {subCategories?.responseData?.map((subCategory) => (
          <SelectItem value={String(subCategory.id)}>{subCategory?.nameKz}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const DocumentFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const categoryId = searchParams.get('categoryId')
  const subCategoryId = searchParams.get('subCategoryId')
  const documentId = searchParams.get('documentId')

  const { data: documents } = useDocumentsQuery({ pageIndex: 0, pageSize: 0, parentCategoryIds: categoryId, parentSubcategoryIds: subCategoryId })

  const onChange = (value: string) => {
    if (value === 'empty') {
      searchParams.delete('documentId');
      setSearchParams(searchParams);

      return;
    }
    searchParams.set('documentId', value);
    setSearchParams(searchParams);
  }

  if (!categoryId || !subCategoryId) {
    return null
  }

  return (
    <Select onValueChange={onChange} value={documentId ?? ''}>
      <SelectTrigger className="max-w-[300px]">
        <SelectValue placeholder="Санат" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="empty">-</SelectItem>
        {documents?.responseData?.map((document) => (
          <SelectItem value={String(document.id)}>{document?.nameKz}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )

}
