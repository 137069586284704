import { PaginationResponse } from '../../../types/response'
import { Api } from '../../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'


interface Response extends PaginationResponse {
  responseData: Link[]
}

export type Link = {
  id: number;
  uri: string;
  nameKz: string;
  nameRu: string;
}

interface State {
  pageIndex: number;
  pageSize: number;
  nameSearchPattern?: string | null;
}

const fetchLinks = async ({ pageIndex, pageSize, nameSearchPattern }: State): Promise<Response> => {
  const response = await Api.get('/links', {
    params: {
      pageIndex,
      pageSize,
      ...(nameSearchPattern ? { nameSearchPattern } : {}),
    }
  })

  return response.data
}

export const linksQueryKey = 'links'

export const useLinksQuery = ({ pageIndex, pageSize, nameSearchPattern }: State) => {
  return useQuery({
    queryKey: [linksQueryKey, pageIndex, pageSize, nameSearchPattern],
    queryFn: () => fetchLinks({pageIndex, pageSize, nameSearchPattern}),
    placeholderData: keepPreviousData
  })
}
