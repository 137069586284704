import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'
import { saveAs } from 'file-saver';

const downloadStatisticsType1 = async () => {
  const response = await Api.get('/statistics/overall/report/type/1', { responseType: 'blob' })

  saveAs(response.data, 'Есеп1.xlsx');
}


export const useDownloadStatisticsType1Mutation = () => {
  return useMutation({
    mutationFn: downloadStatisticsType1,
  })
}

const downloadStatisticsType2 = async () => {
  const response = await Api.get('/statistics/overall/report/type/2', { responseType: 'blob' })

  saveAs(response.data, 'Есеп2.xlsx');
}


export const useDownloadStatisticsType2Mutation = () => {
  return useMutation({
    mutationFn: downloadStatisticsType2,
  })
}

const downloadStatisticsType3 = async () => {
  const response = await Api.get('/statistics/overall/report/type/3', { responseType: 'blob' })

  saveAs(response.data, 'Есеп3.xlsx');
}


export const useDownloadStatisticsType3Mutation = () => {
  return useMutation({
    mutationFn: downloadStatisticsType3,
  })
}
