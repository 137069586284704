import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  id: number;
  nameKz: string;
  uri: string;
}

const editLink = async ({ id, nameKz, uri }: State): Promise<void> => {
  const response = await Api.patch(`/links/${id}`, {
      nameKz: nameKz,
      uri: uri
  })
  return response.data
}

export const editLinkMutationKey = 'editLink'

export const useEditLinkMutation = () => {
  return useMutation({
    mutationKey: [editLinkMutationKey],
    mutationFn: editLink,
  })
}
