import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '../components/ui/navigation-menu'
import { Card, CardContent, CardHeader } from '../components/ui/card'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { AdminMenuLinks, RootAdminMenuLinks } from './data'
import { Button } from '../components/ui/button'
import { useAuthStore } from '../store/authStore'
import { cn } from '../lib/utils'
import Logo from '../assets/logo.svg'
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu'
import { ChevronDown } from 'lucide-react'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../components/ui/resizable'
import { useLayoutStore } from '../store/layoutStore'

export const MainLayout = () => {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated)

  const sidebarSize = useLayoutStore(state => state.sidebarSize)
  const setSidebarSize = useLayoutStore(state => state.setSidebarSize)

  const userEmail = useAuthStore(state => state.userData?.email)
  const userRoles = useAuthStore((state) => state.userData?.userType)
  const logout = useAuthStore((state) => state.logout)

  const location = useLocation()
  const navigate = useNavigate()

  const menuLinks = useMemo(() => {
    if (userRoles?.includes('RootAdmin')) {
      return RootAdminMenuLinks
    }

    return AdminMenuLinks;
  }, [userRoles])

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated])

  const [isOpen, setOpen] = useState(false);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel className="h-screen" defaultSize={sidebarSize} onResize={(value) => setSidebarSize(value)}>
        <Card className="overflow-hidden flex flex-col rounded-none border-none h-full">
          <CardHeader className="h-[60px] p-0">
            <a className="w-[116px] h-auto my-auto mx-auto" href="/">
              <img className="w-[116px] h-auto my-auto mx-auto" src={Logo} alt="Logo" />
            </a>
          </CardHeader>

          <CardContent className="p-0 py-5">
            <NavigationMenu className="block w-full max-w-full">
              <NavigationMenuList className="flex flex-col w-full gap-1">
                {menuLinks &&
                  menuLinks.map((link) => (
                    <MenuLink isActive={link.href === location.pathname} {...link} />
                  ))}
              </NavigationMenuList>
            </NavigationMenu>
          </CardContent>
        </Card>
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel className="overflow-hidden">
        <main className="max-h-screen overflow-y-auto">
          <div className="w-full h-[60px] border-b flex p-3">

            <DropdownMenu open={isOpen} onOpenChange={() => setOpen(false)}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="ml-auto" onClick={() => setOpen(state => !state)}>
                  {userEmail} <ChevronDown className={cn('w-4 h-4 ml-2 mt-0.5 transition-all', {
                  'transform -rotate-180': isOpen
                })} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[150px]" align="end">
                <DropdownMenuItem className="cursor-pointer hover:!bg-destructive/90 hover:!text-destructive-foreground bg-destructive text-destructive-foreground" onClick={logout}>Шығу</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
            <div className="px-6 py-4 text-left">
              <Outlet />
            </div>
        </main>
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}

export const MenuLink = ({
                           name,
                           icon,
                           href,
                           isActive,
                         }: {
  icon: ReactNode
  name: string
  href: string
  isActive: boolean
}) => {
  return (
    <NavigationMenuItem className="w-full">
      <Link className="w-full" to={href}>
        <NavigationMenuLink
          className={cn(`${navigationMenuTriggerStyle()} !w-full !justify-start hover:text-primary`, {
            'text-primary': isActive,
          })}>
          <div className="mr-3">{icon}</div> {name}
        </NavigationMenuLink>
      </Link>
    </NavigationMenuItem>
  )
}
