import { BarChart4Icon, BookType, File, FileClock, FileText, FileType, FileUp, Tags, Users } from 'lucide-react'
import { ReactNode } from 'react'

interface MenuLink {
  name: string
  icon: ReactNode
  href: string
}

export const RootAdminMenuLinks: MenuLink[] = [
  {
    name: 'Қолданушылар',
    icon: <Users />,
    href: '/users',
  },
  {
    name: 'Тақырыптық санат',
    icon: <FileType className="-ml-0.5" />,
    href: '/category',
  },
  {
    name: 'Құжат үлгілері',
    icon: <FileText className="-ml-0.5" />,
    href: '/documents',
  },
  {
    name: 'Техникалық журнал',
    icon: <FileClock />,
    href: '/documentlogs',
  },
  {
    name: 'Нормативтiк құқықтық актiлер',
    icon: <FileUp className="-ml-0.5" />,
    href: '/links',
  },
  {
    name: 'Статистика',
    icon: <BarChart4Icon />,
    href: '/statistics',
  },
]

export const AdminMenuLinks: MenuLink[] = [
  {
    name: 'Тақырыптық санат',
    icon: <FileType className="-ml-0.5" />,
    href: '/category',
  },
  {
    name: 'Құжат үлгілері',
    icon: <FileText className="-ml-0.5" />,
    href: '/documents',
  },
  {
    name: 'Техникалық журнал',
    icon: <FileClock />,
    href: '/documentlogs',
  },
  {
    name: 'Нормативтiк құқықтық актiлер',
    icon: <FileUp className="-ml-0.5" />,
    href: '/links',
  },
  {
    name: 'Статистика',
    icon: <BarChart4Icon />,
    href: '/statistics',
  }
]
