import { Api } from '../../../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Category } from '../../queries/categories'

interface Response {
  responseData: SubCategory[];
}

export type SubCategory = {
  id: number;
  nameKz: string;
  parentCategoryId: string;
  parentCategory: Category;
  displayOrder: number;
}

const fetchSubCategories = async (id: string): Promise<Response> => {
  const response = await Api.get(`/categories/${id}/subcategories`)
  return response.data
}

export const subCategoriesQueryKey = 'subCategoriesQueryKey'

export const useSubCategoriesQuery = (id: string) => {
  return useQuery({
    queryKey: [subCategoriesQueryKey, { id }],
    queryFn: () => fetchSubCategories(id),
    placeholderData: keepPreviousData,
    enabled: !!id
  })
}
