import { useDialogStore } from '../../../store/dialogStore'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog'
import { Button } from '../../../components/ui/button'
import { useSharedStore } from '../../../store/sharedStore'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Api } from '../../../lib/axios'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../components/ui/form'
import { Input } from '../../../components/ui/input'
import { useChangePasswordMutation } from '../mutations/changePasswordMutation'
import { useToast } from '../../../components/ui/use-toast'

const formSchema = z
  .object({
    password:z.string({ required_error: 'Толтыруға міндетті' })
      .trim()
      .refine(value => value.length >= 8, {
        message: 'Құпия сөз 8 немесе одан ары қарай символдан көп болуы керек',
      })
      .refine(value => /[A-Z]/.test(value), {
        message: 'Құпия сөзде кемінде бір Ұялы бөтен бар болуы керек',
      })
      .refine(value => /[a-z]/.test(value), {
        message: 'Құпия сөзде кемінде бір кіші бөтен бар болуы керек',
      })
      .refine(value => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value), {
        message: 'Құпия сөзде кемінде бір арнайы символ бар болуы керек',
      }),
  })
export default function UserChangePasswordDialog() {
  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const selectedUser = useSharedStore((state) => state.selectedUser);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
    },
  })

  const { mutateAsync, isPending, error } = useChangePasswordMutation()
  const { toast } = useToast()
  const submit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync({ id: selectedUser?.id as number, password: data.password })
    toast({
      description: `Құпия сөз сәтті өзгертілді ${selectedUser?.email}`,
      variant: 'success'
    })
    setOpen(null)
  }

  useEffect(() => {
    form.reset();
  }, [open])

  return (
    <Dialog open={open === 'UserChangePassword'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader className="mb-3">
          <DialogTitle>Құпия сөзді өзгерту</DialogTitle>
        </DialogHeader>


        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)} className="space-y-5">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Құпия сөз</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder="Құпия сөз" {...field}  />
                  </FormControl>
                  <FormDescription>
                    Құпия сөз талаптары 8 таңбадан тұрады, кем дегендe бір басты таңба, бір кіші әріп және бір сан болуы тиіс
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className="w-full"
              type="submit"
              loading={isPending}
            >
              Сақтау
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
