import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog'
import { Button } from '../../../components/ui/button'
import { useDialogStore } from '../../../store/dialogStore'
import { useDeleteUserMutation } from '../mutations/deleteUserMutation'
import { useSharedStore } from '../../../store/sharedStore'
import { useToast } from '../../../components/ui/use-toast'
import { useQueryClient } from '@tanstack/react-query'
import { usersQueryKey } from '../queries/queries'
import { useEffect } from 'react'


export default function UserDeleteDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const { mutateAsync, isPending, reset } = useDeleteUserMutation()
  const selectedUser = useSharedStore((state) => state.selectedUser)
  const { toast } = useToast()

  const onDeleteUser = async () => {
    await mutateAsync({ id: selectedUser?.id ?? 1 })
    queryClient.invalidateQueries({ queryKey: [usersQueryKey] })
    toast({
      description: `${selectedUser?.email} пайдаланушысы сәтті өшірілді`,
      variant: 'success'
    })
    setOpen(null)
  }

  useEffect(() => {
    reset()
  }, [open])

  return (
    <Dialog open={open === 'UserDelete'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Жоюды растаңыз</DialogTitle>
          <DialogDescription>Жойғыңыз келетініне сенімдісіз бе? <br/> Бұл әрекетті кері қайтара алмайсыз.</DialogDescription>
        </DialogHeader>


        <DialogFooter className="w-full flex gap-3">
          <Button className="w-full" onClick={() => setOpen(null)} variant="secondary">
            Жоқ
          </Button>
          <Button className="w-full" onClick={onDeleteUser} loading={isPending} variant="destructive">
            Жою
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
