import { Link, useParams } from 'react-router-dom'
import * as React from 'react'
import { SubCategory, useSubCategoriesQuery } from './queries/subCategories'
import { useCategoryByIdQuery } from '../queries/categoryById'
import { Button } from '../../../components/ui/button'
import { ChevronLeft, Loader2, Pencil, RotateCcw, Save, Trash } from 'lucide-react'
import AddSubCategoryDialog from './components/AddSubCategoryDialog'
import { useDialogStore } from '../../../store/dialogStore'
import { useEffect, useMemo, useState } from 'react'
import { Category } from '../queries/categories'
import { useSubCategoryChangeDisplayOrderMutation } from './mutations/changeSubCategoryDisplayOrderMutation'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSharedStore } from '../../../store/sharedStore'
import EditSubCategoryDialog from './components/EditSubCategoryDialog'
import DeleteSubCategoryDialog from './components/DeleteSubCategoryDialog'


export default function SubCategoryPage () {
  const { id} = useParams();

  const setOpen = useDialogStore((state) => state.setOpen)

  const { data: subCategoryData, isFetching, isLoading, refetch } = useSubCategoriesQuery(id ?? '')
  const { data: categoryData } = useCategoryByIdQuery(id ?? '')
  const [items, setItems] = useState<SubCategory[]>(subCategoryData?.responseData ?? []);

  useEffect(() => {
    if (subCategoryData?.responseData) {
      setItems(subCategoryData.responseData)
    }
  }, [subCategoryData])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const newItems = Array.from(items ?? []);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    newItems[result.source.index].displayOrder = result.source.index + 1;
    newItems[result.destination.index].displayOrder = result.destination.index + 1;

    setItems(newItems);
  };

  const handleReset = () => {
    void refetch()
  }

  const isSubCategoryPositionEqual = useMemo(() => {
    return JSON.stringify(subCategoryData?.responseData) === JSON.stringify(items)
  }, [subCategoryData, items])

  const { mutateAsync, isPending: isMutationPending  } = useSubCategoryChangeDisplayOrderMutation()

  const handleSave = async () => {
    const subcategoryIdsByDisplayOrder = items?.map((item) => item.id);
    await mutateAsync({ categoryId: id ?? '', subcategoryIdsByDisplayOrder })
    void refetch()
  }

  const setSubCategory = useSharedStore(state => state.setSelectedSubCategory)
  const handleEdit = (subCategory: SubCategory) => {
    setSubCategory(subCategory)
    setOpen('EditSubCategory')
  }

  const handleDelete = (subCategory: SubCategory) => {
    setSubCategory(subCategory)
    setOpen('DeleteSubCategory')
  }

  return (
    <section className="pb-10 h-[600px]">
      <AddSubCategoryDialog />
      <EditSubCategoryDialog />
      <DeleteSubCategoryDialog />

      <Link className="flex items-center text-sm text-primary mb-3 -ml-1 hover:opacity-80 transition-all" to={'/category'}>
        <ChevronLeft className="w-5 h-5 mr-1" />  Артқа
      </Link>

      <h2 className="text-left font-medium text-xl pb-6">{categoryData?.responseData?.nameKz}</h2>

      <div className="flex justify-end mb-4">
        {!isSubCategoryPositionEqual && !isFetching && !isLoading && (
          <Button loading={isMutationPending} onClick={handleSave} variant="ghost" size="sm">
            <Save className="w-5 h-5" />
          </Button>
        )}
        {!isLoading && !isFetching && (
          <Button onClick={handleReset} className="mr-2" variant="ghost" size="sm">
            <RotateCcw className="w-5 h-5" />
          </Button>
        )}
        <Button onClick={() => setOpen('AddSubCategory')} size="sm">Қосу</Button>
      </div>

      {isFetching || isLoading ? (
        <div className="w-full h-[400px]">
          <Loader2 className="animate-spin w-6 h-6 mx-auto" />
        </div>
      ) : (
        <>
          {items?.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items?.map((subCategory, index) => (
                      <Draggable key={subCategory.id} index={index} draggableId={`item-${subCategory.id}`}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="w-full bg-white min-h-[65px] flex justify-between items-center p-4 rounded-lg cursor-pointer hover:shadow border mb-4 pr-0"
                          >
                            <div className="flex items-center">
                              <div className="text-base font-medium max-w-[450px]">{subCategory.nameKz}</div>
                            </div>

                            <div className="flex">
                              <div className="flex gap-2 mr-4">
                                <Button onClick={() => handleEdit(subCategory)} variant="ghost" size="sm">
                                  <Pencil className="w-4 h-4" />
                                </Button>

                                <Button onClick={() => handleDelete(subCategory)} variant="ghost" size="sm">
                                  <Trash className="w-4 h-4" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </>
      )}
    </section>
  )
}
