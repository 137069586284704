import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/queryParams'
import { useLogsQuery } from './queries/logsQuery'
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { columns } from './components/columns'
import { DataTable } from '../../components/ui/data-table'
import { Input } from '../../components/ui/input'
import { useEffect, useState } from 'react'
import { useDebounce, useIsFirstRender } from 'usehooks-ts'
import { getPageCount } from '../../helpers/pagination'


export default function LogsPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page')) || DEFAULT_PAGE
  const size = Number(searchParams.get('size')) || DEFAULT_PAGE_SIZE
  const sortProperty = searchParams.get('sortProperty');
  const sortOrder = searchParams.get('sortOrder');
  const nameSearchPattern = searchParams.get('nameSearchPattern');

  const { data, isFetching } = useLogsQuery({ pageIndex: page, pageSize: size, nameSearchPattern, sortProperty, sortOrder })
  const totalPages = getPageCount(data?.totalSize ?? 0, size)
  const [sorting, setSorting] = React.useState<SortingState>([])


  const table = useReactTable({
    data: data?.responseData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: totalPages,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    enableSorting: true,
    state: {
      pagination: { pageSize: size, pageIndex: page },
      sorting: [...(sortOrder && sortProperty ? [{ id: sortProperty, desc: sortOrder === 'Descending' }] : [])]
    },
  })

  useEffect(() => {
    if (sorting.length > 0) {
      const { id, desc } = sorting[0]
      searchParams.set('sortProperty', id)
      searchParams.set('sortOrder', desc ? 'Descending' : 'Ascending')
      setSearchParams(searchParams)
    }
  }, [sorting])

  return (
    <section className="pb-10">

      <h2 className="text-left font-medium text-xl pb-6">Техникалық журнал</h2>

      <div className="mb-3 w-full">
        <FilterInput />
      </div>

      <DataTable table={table} isLoading={isFetching} isPagination={true} />
    </section>
  )
}

const FilterInput = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const nameSearchPattern = searchParams.get('nameSearchPattern');

  const isFirst = useIsFirstRender()
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue, 500)

  useEffect(() => {
    if (nameSearchPattern) {
      setSearchValue(nameSearchPattern)
    }
  }, [])

  useEffect(() => {
    if (isFirst) {
      return
    }

    if (!debouncedValue) {
      searchParams.delete('nameSearchPattern');
      setSearchParams(searchParams);
      return
    }

    searchParams.set('nameSearchPattern', debouncedValue);
    searchParams.set('page', '0');
    setSearchParams(searchParams);
  }, [debouncedValue])

  return (
    <Input className="max-w-[300px]" value={searchValue} onChange={(event) => setSearchValue(event.target.value)} placeholder="Құжаттың атын еңгізіңіз" />
  )
}
