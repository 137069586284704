import { ColumnDef } from "@tanstack/react-table"
import { ActionType, Log } from '../queries/logsQuery'
import { format } from 'date-fns'
import { Button } from '../../../components/ui/button'
import { ArrowUp } from 'lucide-react'
import { cn } from '../../../lib/utils'

const actionTypeMap: {[key in ActionType]: string} = {
  Created: 'Қосылған',
  Modified: 'Өзгертілген',
  Removed: 'Өшірілген',
}

export const columns: ColumnDef<Log>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "actionType",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Әрекет түрі
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
    cell: ({ row }) => {
      const actionType = row.original.actionType;
      return (
        <span>{actionTypeMap[actionType]}</span>
      )
    }
  },
  {
    accessorKey: "searchDataColumn",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Аты
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
  },
  {
    accessorKey: "actionByUserEmail",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Электрондық пошта
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
  },
  {
    accessorKey: 'eventMoment',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Күні
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
    cell: ({ row }) => {
      const eventMoment = row.original.eventMoment;

      return (
        <span>{format(new Date(eventMoment), 'dd.MM.yyyy HH:mm')}</span>
      )
    }
  }
]
