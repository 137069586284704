import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'


interface State {
  id: number;
}

const deleteCategoryItem = async ({ id }: State): Promise<void> => {
  const response = await Api.delete(`/categories/${id}`);

  return response.data;
}

export const deleteCategoryItemMutationKey = 'deleteCategoryItemMutationKey';

export const useDeleteCategoryItemMutation = () => {
  return useMutation({
    mutationKey: [deleteCategoryItemMutationKey],
    mutationFn: deleteCategoryItem,
  })
}

