import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import { useDialogStore } from '../../../store/dialogStore'
import { useToast } from '../../../components/ui/use-toast'
import { useDeleteDocumentMutation } from '../mutations/deleteDocumentMutation'
import { useSharedStore } from '../../../store/sharedStore'
import { DOCUMENTS_QUERY_KEY } from '../queries/documentsQuery'
import { Button } from '../../../components/ui/button'
import { useQueryClient } from '@tanstack/react-query'


export default function DeleteDocumentDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore(state=> state.open)
  const setOpen = useDialogStore(state=> state.setOpen)

  const id = useSharedStore(state => state.selectedDocument?.id)

  const { mutateAsync, isPending } = useDeleteDocumentMutation();
  const { toast } = useToast()

  const onDelete = async () => {
    if (!id) return

    await mutateAsync({ id })
    void queryClient.invalidateQueries({
      queryKey: [DOCUMENTS_QUERY_KEY]
    })
    setOpen(null)
    toast({
      title: 'Құжат сәтті жойылды',
      variant: 'success',
    })
  }

  return (
    <Dialog open={open === 'DeleteDocument'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Жоюды растаңыз</DialogTitle>
          <DialogDescription>
            Құжатты жойғыңыз келетініне сенімдісіз бе? <br/>
            Кейін бұл әрекетті кері қайтара алмайсыз.
          </DialogDescription>
        </DialogHeader>

        <div className="flex w-full gap-3 mt-3">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setOpen(null)}
          >
            Жоқ
          </Button>
          <Button
            className="w-full"
            variant="destructive"
            loading={isPending}
            onClick={onDelete}
          >
            Жою
          </Button>
        </div>

      </DialogContent>
    </Dialog>
  )
}
