import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'

interface State {
  email: string;
  password: string;
}

const createUser = async ({ email, password }: State): Promise<void> => {
  const response = await Api.post('/application-users', { email, password })
  return response.data
}

export const createUserMutationKey = 'createUser'

export const useCreateUserMutation = () => {
  return useMutation<void, unknown, State>({
    mutationKey: ['createUserMutationKey'],
    mutationFn: createUser,
  })
}
