import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'


interface State {
  id: number;
}

const deleteDocument = async ({ id }: State): Promise<Response> => {
  const response = await Api.delete(`/documents/${id}`)

  return response.data
}

const DELETE_DOCUMENT_MUTATION_KEY = 'deleteDocumentMutation'

export const useDeleteDocumentMutation = () => {
  return useMutation({
    mutationKey: [DELETE_DOCUMENT_MUTATION_KEY],
    mutationFn: deleteDocument,
  })
}
