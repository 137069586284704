import { Api } from '../../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

interface Response {
  responseData: Category[]
}

export type Category = {
  id: number;
  nameKz: string;
  displayOrder: number;
  iconBlob: {
    id: string;
    uri: string;
  }
}

const fetchCategories = async (): Promise<Response> => {
  const response = await Api.get('/categories?pageIndex=0&pageSize=0');

  return response.data;
}

export const categoriesQueryKey = 'categoriesQueryKey';

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: [categoriesQueryKey],
    queryFn: fetchCategories,
    placeholderData: keepPreviousData
  })
}
