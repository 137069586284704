import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import { useDialogStore } from '../../../store/dialogStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSharedStore } from '../../../store/sharedStore'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/ui/form'
import { Textarea } from '../../../components/ui/textarea'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select'
import { cn } from '../../../lib/utils'
import { Button } from '../../../components/ui/button'
import { useCategoryIconsQuery } from '../queries/categoryIconsQuery'
import { useEffect } from 'react'
import { useUpdateCategoryItemMutation } from '../mutations/updateCategoryItemMutation'
import { Api } from '../../../lib/axios'
import { categoriesQueryKey } from '../queries/categories'
import { useToast } from '../../../components/ui/use-toast'

const formSchema = z
  .object({
    nameKz: z.string({ required_error: 'Толтыруға міндетті' }).trim().max(60, { message: 'Атауы 60 символдан аса болмасын' }),
    iconBlobId: z.string({ required_error: 'Толтыруға міндетті' }),
  })

export default function EditCategoryDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const category = useSharedStore(state => state.selectedCategory)
  const { data } = useCategoryIconsQuery()

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (category) {
      form.setValue('nameKz', category.nameKz)
      form.setValue('iconBlobId', String(category?.iconBlob?.id ?? ''))
    }
  }, [category])


  const { mutateAsync, error, isPending } = useUpdateCategoryItemMutation()
  const { toast } = useToast()

  const submit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync({ id: category?.id as number, ...data })
    void queryClient.invalidateQueries({ queryKey: [categoriesQueryKey] })
    toast({
      description: 'Тақырыптық санат өзгертілді',
      variant: 'success',
    })
    setOpen(null)
  }

  return (
    <Dialog open={open === 'EditCategory'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Тақырыптық санатты өзгерту
          </DialogTitle>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)} className="space-y-5">
            <FormField
              control={form.control}
              name="nameKz"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тақырыптық санат атауы</FormLabel>
                  <FormControl>
                    <Textarea maxLength={60} placeholder="Тақырыптық санат атауын енгізіңіз" {...field}  />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="iconBlobId"
              render={({ field }) => {
                const selectedIcon = data?.responseData?.find((item) => String(item.id) === String(field.value));

                return (
                  (
                    <FormItem>
                      <FormLabel>Суретті таңдау</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Суретті таңдау">
                              <div className="flex items-center">
                                <img className="mr-3" src={selectedIcon?.uri} alt={selectedIcon?.name} />
                                {selectedIcon?.name}
                              </div>
                            </SelectValue>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent viewportClassName="grid grid-cols-5">
                          {data?.responseData?.map((item, index) => (
                            <SelectItem key={index} value={item.id} className={cn('cursor-pointer', {
                              'bg-primary/25 text-accent-foreground focus:bg-primary/25': field.value === String(item.id)
                            })}>
                              <img src={item.uri} alt={item.name} />
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )
                )
              }}
            />

            <Button
              className="w-full"
              type="submit"
              loading={isPending}
            >
              Сақтау
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
