import { useDialogStore } from '../../../store/dialogStore'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import { useSharedStore } from '../../../store/sharedStore'
import { useDocumentByIdQuery } from '../queries/documentByIdQuery'
import { z } from 'zod'
import { Loader2, } from 'lucide-react'
import * as React from 'react'
import DocumentForm, { documentFormSchema } from './DocumentForm'
import { EditDocumentState, useEditDocumentMutation } from '../mutations/editDocumentMutation'
import { DOCUMENTS_QUERY_KEY } from '../queries/documentsQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '../../../components/ui/use-toast'


export default function EditDocumentDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore(state => state.open)
  const setOpen = useDialogStore(state => state.setOpen)

  const id = useSharedStore(state => state.selectedDocument?.id)

  const { data: documentData, isLoading, refetch } = useDocumentByIdQuery({ id })

  const { mutateAsync, isPending } = useEditDocumentMutation();
  const { toast } = useToast()

  const onSubmit = async (data: z.infer<typeof documentFormSchema>) => {
    const formattedData = formatData(data)

    await mutateAsync(formattedData)
    void queryClient.invalidateQueries({
      queryKey: [DOCUMENTS_QUERY_KEY]
    })
    void refetch();
    setOpen(null)
    toast({
      title: 'Құжат сәтті өзгертілді',
      variant: 'success',
    })
  }

  return (
    <Dialog open={open === 'EditDocument'} onOpenChange={() => setOpen(null)}>
      <DialogContent className="max-w-[750px] max-h-[800px] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            Құжатты өзгерту
          </DialogTitle>
          <DialogDescription>
            Құжатты өзгерту үшін деректерді енгізіңіз
          </DialogDescription>
        </DialogHeader>

        {isLoading && (
          <Loader2 className="animate-spin w-5 h-5 mx-auto my-5" />
        )}

        {documentData?.responseData && (
          <DocumentForm data={documentData.responseData} onSubmit={onSubmit} loading={isPending} submitTitle="Өзгерту" />
        )}
      </DialogContent>
    </Dialog>
  )
}

const formatData = (data: z.infer<typeof documentFormSchema>): EditDocumentState => {
  const id = useSharedStore.getState().selectedDocument?.id

  const formattedData: EditDocumentState = {
    id: String(id),
    nameKz: data.nameKz,
    nameRu: data.nameRu,
    sourceContentBlobId: data.sourceContentBlob.id,
    filledContentBlobId: data?.filledContentBlob?.id,
    parentSubcategoryIds: [],
  }
  data.parentSubcategories.map((category) => category.subCategoryIds.map(subCategory => formattedData.parentSubcategoryIds.push(subCategory)))

  return formattedData
}


