import { ColumnDef } from "@tanstack/react-table"
import { Document } from '../queries/documentsQuery'
import { format } from 'date-fns'
import { Button } from '../../../components/ui/button'
import { ArrowUp, Pencil, Trash } from 'lucide-react'
import { cn } from '../../../lib/utils'
import { useSharedStore } from '../../../store/sharedStore'
import { useDialogStore } from '../../../store/dialogStore'

export const columns: ColumnDef<Document>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "nameKz",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Қазақша атауы
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
  },
  {
    accessorKey: "nameRu",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Орысша атауы
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
  },
  {
    accessorKey: "createdOn",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Құрылған күні
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
    cell: props => {
      const date = new Date(props.row.original.createdOn)
      return (
        <span>{format(date, 'dd.MM.yyyy HH:ss')}</span>
      )
    }
  },
  {
    accessorKey: "lastUpdatedOn",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Жаңартылған күні
          <ArrowUp className={cn(`ml-2 h-4 w-4 transition-all`, {
            'rotate-180': column.getIsSorted() === 'asc',
          })} />
        </Button>
      )
    },
    cell: props => {
      const date = new Date(props.row.original.lastUpdatedOn)
      return (
        <span>{format(date, 'dd.MM.yyyy HH:ss')}</span>
      )
    }
  },
  {
    accessorKey: "actions",
    header: 'Әрекет',
    cell: ({ row }) => {
      const document = row.original
      const setDocument = useSharedStore.getState().setSelectedDocument
      const setOpen = useDialogStore.getState().setOpen

      const onEdit = () => {
        setDocument(document);
        setOpen('EditDocument');
      }

      const onDelete = () => {
        setDocument(document);
        setOpen('DeleteDocument');
      }

      return (
        <span className="flex gap-1">
          <Button onClick={onEdit} className="rounded-full" size="sm" variant="ghost">
            <Pencil className="w-4 h-4" />
          </Button>

          <Button onClick={onDelete} className="rounded-full" size="sm" variant="ghost">
            <Trash className="w-4 h-4" />
          </Button>
        </span>
      )
    }
  },
]
