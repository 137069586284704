import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import * as z from 'zod'
import { useDialogStore } from '../../../store/dialogStore'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../components/ui/form'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { Api } from '../../../lib/axios'
import { useCreateUserMutation } from '../mutations/createUserMutation'
import { useToast } from '../../../components/ui/use-toast'
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { usersQueryKey } from '../queries/queries'


const formSchema = z.object({
  email: z
    .string({ required_error: 'Толтыруға міндетті' })
    .email("Жарамсыз электрондық пошта"),
  password:z.string({ required_error: 'Толтыруға міндетті' })
    .trim()
    .refine(value => value.length >= 8, {
      message: 'Құпия сөз 8 немесе одан ары қарай символдан көп болуы керек',
    })
    .refine(value => /[A-Z]/.test(value), {
      message: 'Құпия сөзде кемінде бір Ұялы бөтен бар болуы керек',
    })
    .refine(value => /[a-z]/.test(value), {
      message: 'Құпия сөзде кемінде бір кіші бөтен бар болуы керек',
    })
    .refine(value => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value), {
      message: 'Құпия сөзде кемінде бір арнайы символ бар болуы керек',
    }),
})

export default function UserCreateDialog () {
  const queryClient = useQueryClient()

  const open = useDialogStore((state) => state.open)
  const setOpen = useDialogStore((state) => state.setOpen)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const { mutateAsync, isPending, error, reset } = useCreateUserMutation()
  const { toast } = useToast()

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync(data)
    void queryClient.invalidateQueries({ queryKey: [usersQueryKey] })
    toast({
      description: `Пайдаланушы сәтті қосылды`,
      variant: 'success'
    })
    setOpen(null)
  }

  useEffect(() => {
    form.reset();
    reset()
  }, [open])

  return (
    <Dialog open={open === 'UserCreate'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Пайдаланушыны қосу</DialogTitle>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Электрондық пошта</FormLabel>
                  <FormControl>
                    <Input type="email" placeholder="Электрондық поштаңызды енгізіңіз" {...field}  />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Құпия сөз</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder="Құпия сөзді енгізіңіз" {...field}  />
                  </FormControl>
                  <FormDescription>
                    Құпия сөз талаптары 8 таңбадан тұрады, кем дегендe бір басты таңба, бір кіші әріп және бір сан болуы тиіс
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className="w-full"
              type="submit"
              loading={isPending}
            >
              Қабылдау
            </Button>
          </form>
        </Form>

      </DialogContent>
    </Dialog>
  )
}
