import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'


interface AuthState {
  isAuthenticated: boolean
  token: string | null;
  userData: IUser | null;
  setUserData: (userData: IUser) => void;
  setToken: (token: string) => void;
  logout: () => void;
}

export interface IUser {
  id: number;
  email: string;
  userType: UserType
}

export type UserType = 'RootAdmin' | 'Admin'

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        isAuthenticated: false,
        token: null,
        userData: null,
        setToken(token) {
          set({token, isAuthenticated: true})
        },
        setUserData(userData) {
          set({userData})
        },
        logout: () => {
          set({ isAuthenticated: false })
          set({ token: null })
          set({ userData: null })
          useAuthStore.persist.clearStorage();
          localStorage.clear()
        },
      }),
      {
        name: 'auth-storage',
      },
    ),
  ),
)
