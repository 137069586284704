import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type Open =
  'UserChangePassword' |
  'UserDelete' |
  'UserCreate' |
  'AddCategory' |
  'EditCategory' |
  'DeleteCategory' |
  'AddSubCategory' |
  'EditSubCategory' |
  'DeleteSubCategory' |
  'AddLink' |
  'EditLink' |
  'DeleteLink' |
  'CreateDocument' |
  'EditDocument' |
  'DeleteDocument' |
  null
interface DialogState {
  open: Open
  setOpen: (open: Open) => void
}

export const useDialogStore = create<DialogState>()(
  devtools(
      (set) => ({
        open: null,
        setOpen(open) {
          set({open: open})
        }
      }),
  ),
)
