import { ColumnDef } from "@tanstack/react-table"
import { UserType } from '../../../store/authStore'
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu'
import { Button } from '../../../components/ui/button'
import { MoreHorizontal, Pencil, Trash } from 'lucide-react'
import { useDialogStore } from '../../../store/dialogStore'
import { useSharedStore } from '../../../store/sharedStore'

type User = {
  id: number
  email: string
  userType: UserType
}

export const columns: ColumnDef<User>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "email",
    header: "Электрондық пошта",
  },
  {
    accessorKey: "actions",
    header: 'Әрекет',
    cell: ({ row }) => {
      const id = row.original.id
      const email = row.original.email

      const setOpen = useDialogStore.getState().setOpen
      const setUser = useSharedStore.getState().setSelectedUser

      const handleChangePassword = () => {
        setUser({ id, email });
        setOpen('UserChangePassword')
      }

      const handleDelete = () => {
        setUser({ id, email });
        setOpen('UserDelete')
      }

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={handleChangePassword}>
              <Pencil className="mr-2 h-4 w-4" />
              <span>Құпия сөзді өзгерту</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleDelete} className="cursor-pointer hover:!bg-destructive/90 hover:!text-destructive-foreground bg-destructive text-destructive-foreground">
              <Trash className="mr-2 h-4 w-4" />
              <span>Жою</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    }
  },
]
