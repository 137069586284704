import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../components/ui/dialog'
import { useDialogStore } from '../../../store/dialogStore'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '../../../components/ui/form'
import LinkForm from './LinkForm'
import { Button } from '../../../components/ui/button'
import { useAddLinkMutation } from '../mutations/addLinkMutation'
import { useQueryClient } from '@tanstack/react-query'
import { linksQueryKey } from '../queries/linksQuery'
import { useToast } from '../../../components/ui/use-toast'
import { Api } from '../../../lib/axios'
import { useEffect } from 'react'

const formSchema = z
  .object({
    nameKz: z.string({ required_error: 'Толтыруға міндетті' }).trim().max(60, { message: 'Атауы 60 символдан аса болмасын' }),
    uri: z.string({ required_error: 'Толтыруға міндетті' }).url('URL енгізіңіз'),
  })

export default function AddLinkDialog() {
  const queryClient = useQueryClient()

  const open = useDialogStore(state => state.open)
  const setOpen = useDialogStore(state => state.setOpen)

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    form.reset()
  }, [open])

  const { mutateAsync, error, isPending } = useAddLinkMutation()
  const { toast } = useToast()

  const submit = async (data: z.infer<typeof formSchema>) => {
    await mutateAsync(data);
    void queryClient.invalidateQueries({ queryKey: [linksQueryKey] })
    toast({
      description: 'Нормативтік құқықтық акт қосылды',
      variant: 'success',
    })
    setOpen(null)
  }

  return (
    <Dialog open={open === 'AddLink'} onOpenChange={() => setOpen(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Нормативтiк құқықтық актiнің қосу</DialogTitle>
        </DialogHeader>

        {Api.isAxiosError(error) && (
          <span className="text-sm text-destructive">
            {error?.response?.data?.responseClientMessage}
          </span>
        )}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)}>
            <LinkForm />

            <Button
              className="w-full mt-5"
              type="submit"
              loading={isPending}
            >
              Қабылдау
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
