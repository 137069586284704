import { Card, CardContent, CardHeader } from '../../components/ui/card'
import Logo from '../../assets/logo.svg'

import * as z from "zod"
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { Api } from '../../lib/axios'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'
import { IUser, useAuthStore } from '../../store/authStore'
import { useNavigate } from 'react-router-dom'

const formSchema = z.object({
  email: z
    .string({ required_error: 'Толтыруға міндетті' })
    .email("Жарамсыз электрондық пошта"),
  password: z.string({ required_error: "Толтыруға міндетті" }),
})

export default function Login() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('')

  const setToken = useAuthStore((state) => state.setToken)
  const setUserData = useAuthStore((state) => state.setUserData)
  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      setLoading(true)
      const loginResponse: AxiosResponse<{responseData: { accessToken: string }}> = await Api.post('/identity/sign-in', data);
      setToken(loginResponse.data.responseData.accessToken)

      const identityResponse: AxiosResponse<{ responseData: IUser }> = await Api.get('/identity/me');
      setUserData(identityResponse.data.responseData)

      if (identityResponse.data.responseData.userType === 'RootAdmin') {
        navigate('/users')

        return
      }

      navigate('/category')
    } catch (error) {
      if (error instanceof AxiosError<{responseClientMessage: string}>) {
        setError(error?.response?.data?.responseClientMessage)
      }

    } finally {
      setLoading(false)
    }
  }

  return (
    <main className="w-screen h-screen bg-gradient-to-r from-gray-100 to-white min-h-screen flex items-center justify-center">
      <Card className="w-[450px]">
        <CardHeader>
          <img className="w-[116px] h-auto mx-auto" src={Logo} alt='logo' />
        </CardHeader>

        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5 text-left">
              <span className="text-sm text-destructive">{error}</span>

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Электрондық пошта</FormLabel>
                    <FormControl>
                      <Input placeholder="Электрондық пошта" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Құпия сөз</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder="Құпия сөз" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button disabled={isLoading} loading={isLoading} className="w-full" type="submit">Кіру</Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </main>
  )
}
