import { useMutation } from '@tanstack/react-query'
import { Api } from '../../../../lib/axios'

interface State {
  categoryId: string | number;
  subCategoryId: string | number;
  nameKz: string;
}

const updateSubCategoryItem = async ({ categoryId, subCategoryId, ...rest }: State): Promise<void> => {
  const response = await Api.patch(`/categories/${categoryId}/subcategories/${subCategoryId}`, { ...rest });

  return response.data;
}

export const updateSubCategoryItemMutationKey = 'updateSubCategoryItemMutationKey';

export const useUpdateSubCategoryItemMutation = () => {
  return useMutation({
    mutationKey: [updateSubCategoryItemMutationKey],
    mutationFn: updateSubCategoryItem,
  })
}
