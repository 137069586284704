import { Category } from './categories'
import { Api } from '../../../lib/axios'
import { useQuery } from '@tanstack/react-query'

interface Response {
  responseData: Category
}

const fetchCategoryById = async (id: string): Promise<Response> => {
  const response = await Api.get(`/categories/${id}`);

  return response.data;
}

export const categoryByIdQueryKey = 'categoryByIdQueryKey';

export const useCategoryByIdQuery = (id: string) => {
  return useQuery({
    queryKey: [categoryByIdQueryKey, id],
    queryFn: () => fetchCategoryById(id),
  })
}
