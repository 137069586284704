import { Api } from '../../../lib/axios'
import { useMutation } from '@tanstack/react-query'


interface State {
  id: number
}

const deleteLink = async ({id}: State): Promise<void> => {
  const response = await Api.delete(`/links/${id}`)

  return response.data
}

export const deleteLinkMutationKey = 'deleteLink'

export const useDeleteLinkMutation = () => {
  return useMutation({
    mutationKey: [deleteLinkMutationKey],
    mutationFn: deleteLink,
  })
}
