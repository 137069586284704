import { Api } from '../../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'

interface Response {
  responseData: Statistics[]
}

export type Statistics = {
  day: string;
  totalUploadedDocuments: number;
  totalPreviewedDocuments: number;
  totalDownloadedDocuments: number;
}

interface State {
  from: Date;
  to: Date;
  categoryId?: string;
  subCategoryId?: string;
  documentId?: string;
}

const fetchStatistics = async ({ from, to, categoryId, subCategoryId, documentId }:State): Promise<Response> => {
  const response = await Api.get('/statistics/overall', {
    params: {
      from: format(from, 'yyyy-MM-dd'),
      to: format(to, 'yyyy-MM-dd'),
      ...(categoryId ? { categoryId } : {}),
      ...(subCategoryId ? { subCategoryId } : {}),
      ...(documentId ? { documentId } : {}),
    }
  });
  return response.data;
}

export const statisticsQueryKey = 'statistics';

export const useStatisticsQuery = ({ from, to, categoryId, subCategoryId, documentId }: State) => {
  return useQuery({
    queryKey: [statisticsQueryKey, from, to, categoryId, subCategoryId, documentId],
    queryFn: () => fetchStatistics({ from, to, categoryId, subCategoryId, documentId}),
    placeholderData: keepPreviousData
  })
}
