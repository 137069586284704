import { PaginationResponse } from '../../../types/response'
import { Api } from '../../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { DEFAULT_PAGE_SIZE } from '../../../constants/queryParams'
import { ContentBlob } from '../mutations/createDocumentMutation'
import { SubCategory } from '../../category/[id]/queries/subCategories'

interface Response extends PaginationResponse {
  responseData: Document[]
}

export interface Document {
  id: number;
  nameKz: string;
  nameRu: string;
  createdOn: Date;
  lastUpdatedOn: Date;
  sourceContentBlob: ContentBlob;
  filledSampleContentBlob?: ContentBlob;
  parentSubcategories: SubCategory[];
}

interface State {
  pageIndex: number;
  pageSize: number;
  sortProperty?: string | null;
  sortOrder?: string | null;
  nameSearchPattern?: string | null;
  parentCategoryIds?: string | null;
  parentSubcategoryIds?: string | null;
}

const fetchDocuments = async ({ pageIndex, pageSize, sortProperty, sortOrder, nameSearchPattern, parentCategoryIds, parentSubcategoryIds }: State): Promise<Response> => {
  const response = await Api.get('/documents', {
    params: {
      pageIndex,
      pageSize,
      ...(sortProperty ? { sortProperty } : {}),
      ...(sortOrder ? { sortOrder } : {}),
      ...(nameSearchPattern ? { nameSearchPattern } : {}),
      ...(parentCategoryIds ? { parentCategoryIds } : {}),
      ...(parentSubcategoryIds ? { parentSubcategoryIds } : {}),
    }
  })

  return { ...response.data }
}

export const DOCUMENTS_QUERY_KEY = 'documents'

export const useDocumentsQuery = ({ pageIndex, pageSize, sortProperty, sortOrder, nameSearchPattern, parentCategoryIds, parentSubcategoryIds }: State) => {
  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, { pageIndex, pageSize, sortProperty, sortOrder, nameSearchPattern, parentCategoryIds, parentSubcategoryIds }],
    queryFn: () => fetchDocuments({ pageIndex, pageSize, sortProperty, sortOrder, nameSearchPattern, parentCategoryIds, parentSubcategoryIds }),
    placeholderData: keepPreviousData
  })
}
